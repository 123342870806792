import { LinkListItem, LinkList, Col, Select, Row, Button, Card, CardTitle, Alert, Icon, ModalFooter, ModalBody, ModalHeader, Modal, Tooltip } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import { CustomDateField } from '../custom/CustomDateField';
import appuntamentiService from '../service/appuntamentiService';
import '../../App.css';
import { wait } from './../common/Loading';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';

function AppuntamentiPage({ toggleTermsAndConditions }) {
    const [codiceFiscale, setCodiceFiscale] = useState('GLTFNC93B05H501U');

    const [isModalitaEnabled, setIsModalitaEnabled] = useState(false);
    const [isStudioEnabled, setIsStudioEnabled] = useState(false);
    const [isMotivoEnabled, setIsMotivoEnabled] = useState(false);

    const modalidaList = [
        {
            'label': 'Studio',
            'value': 'S'
        },
        {
            'label': 'Domicilio',
            'value': 'D'
        }
    ];
    const [studioList, setStudioList] = useState();
    const [motivoList, setMotivoList] = useState();
    const [appuntamentoList, setAppuntamentoList] = useState([]);

    const formatDate = (date) => {
        return (date.getFullYear() + '-' + ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + ((date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate())))
    }

    const [inputValues, setInputValues] = useState({
        'modalita': null,
        'studio': null,
        'motivo': null,
        'data': formatDate(new Date())
    });
    const [alert, setAlert] = useState({
        'state': false,
        'text': '',
        'type': ''
    });
    const [isSearchThroughArrows, setIsSearchThroughArrows] = useState(false);

    const [confirmModal, setConfirmModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [cancelAllModalState, setCancelAllModalState] = useState(false);
    const [cancelAllModalDate, setCancelAllModalDate] = useState('');
    const [confirmModalAppuntamento, setConfirmModalAppuntamento] = useState('');
    const [cancelModalAppuntamento, setCancelModalAppuntamento] = useState('');
    const [selectedWeeks, setSelectedWeeks] = useState(inputValues.data);

    const [tooltipAnnullaEConferma, setTooltipAnnullaEConferma] = useState({
        "id": "",
        "statoAnnulla": false,
        "statoConferma": false
    })
    const [annullaAppuntamentoTooltip, setAnnullaAppuntamentoTooltip] = useState(false);
    const [annullaTuttiAppuntamentiTooltip, setAnnullaTuttiAppuntamentiTooltip] = useState(
        {
            "id": "",
            "stato": false
        }
    );
    const [cercaTooltip, setCercaTooltip] = useState(false);
    const [settimanaPrecedenteTooltip, setSettimanaPrecedenteTooltip] = useState(false);
    const [settimanaSuccessivaTooltip, setSettimanaSuccessivaTooltip] = useState(false);

    const [deleteError, setDeleteError] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [listOfClientsWithNoNotification, setListOfClientsWithNoNotification] = useState([]);
    
    const handleError = (err) => {
        if (err.response && err.response.status === 512) {
            toggleTermsAndConditions(true);
        } else if (err.response && (err.response.status === 502 && err.response.data === "Cannot delete appuntamenti in the past")) {
            setDeleteError(true);
        } 
    }

    useEffect(() => {
        wait(appuntamentiService.studioList({}))
            .then((response) => {
                let studioListTmp = [];
                response.data.map((studio) => {
                    studioListTmp.push({
                        'label': studio.nomeStudio,
                        'value': studio.idStudio
                    });
                });
                setStudioList(studioListTmp);
            }).catch((err) => {
                handleError(err);
            });
        wait(appuntamentiService.motivoList())
            .then((response) => {
                let motivoListTmp = [];
                response.data.map((motivo) => {
                    motivoListTmp.push({
                        'label': motivo.nomeAppuntamento,
                        'value': motivo.idTipoAppuntamento
                    });
                });
                setMotivoList(motivoListTmp);
            }).catch((err) => {
                handleError(err);
            });
    }, []);

    useEffect(() => {
        handleAppuntamentiSearch();
    }, []);

    useEffect(() => {
        if (isSearchThroughArrows) {
            handleAppuntamentiSearch();
            setSelectedWeeks(inputValues.data);
            setIsSearchThroughArrows(false);
        }
    }, [inputValues]);

    const handleInputChange = (inputField, event) => {
        if (alert.state) {
            setAlert({
                'state': false,
                'text': '',
                'type': ''
            });
        }
        let inputValuesTmp = {
            'modalita': inputValues.modalita,
            'studio': inputValues.studio,
            'motivo': inputValues.motivo,
            'data': inputValues.data
        }
        switch (inputField) {
            case 'modalita':
                inputValuesTmp.modalita = (event == null) ? null : event.value;
                break;
            case 'studio':
                inputValuesTmp.studio = (event == null) ? null : event.value;
                break;
            case 'motivo':
                inputValuesTmp.motivo = (event == null) ? null : event.value;
                break;
            case 'data':
                inputValuesTmp.data = (event.target.value === '') ? null : event.target.value
        }
        setInputValues(inputValuesTmp);
    };

    const customStylesInputSelect = {
        menuPortal: base => ({
            ...base,
            zIndex: 999
        })
    };

    const handleAppuntamentiSearch = () => {
        if (inputValues.data == null) {
            setAlert({
                'state': true,
                'text': 'Data obbligatoria!',
                'type': 'danger'
            });
            return;
        }
        setSelectedWeeks(inputValues.data);
        let payload = {
            'tipoModalita': inputValues.modalita,
            'idStudio': inputValues.studio,
            'idTipoAppuntamento': inputValues.motivo,
            'data': inputValues.data
        };
        wait(appuntamentiService.appuntamentiSearch(payload))
            .then((response) => {
                let appuntamentoListTmp = [];
                response.data.map((appuntamento) => {
                    appuntamentoListTmp.push({
                        'data': appuntamento.data,
                        'indirizzo': appuntamento.indirizzo,
                        'nomeAssistito': appuntamento.nomeAssistito,
                        'cognomeAssistito': appuntamento.cognomeAssistito,
                        'nomeAppuntamento': appuntamento.nomeAppuntamento,
                        'oraA': appuntamento.oraA,
                        'oraDa': appuntamento.oraDa,
                        'modalita': (appuntamento.tipoModalita === 'S') ? 'Studio' : 'Domicilio',
                        'flgConferma': appuntamento.flgConferma,
                        'idAppuntamento': appuntamento.idAppuntamento,
                        'nomeStudio': appuntamento.nomeStudio
                    });
                });
                setAppuntamentoList(appuntamentoListTmp);
            }).catch((err) => { handleError(err); });
    };

    const getItalianWeekDay = (date) => {
        switch (date.getDay()) {
            case 1:
                return 'Lunedì';
            case 2:
                return 'Martedì';
            case 3:
                return 'Mercoledì';
            case 4:
                return 'Giovedì';
            case 5:
                return 'Venerdì';
        }
    };

    const getItalianMonthName = (date) => {
        switch (date.getMonth()) {
            case 0:
                return 'Gennaio';
            case 1:
                return 'Febbraio';
            case 2:
                return 'Marzo';
            case 3:
                return 'Aprile';
            case 4:
                return 'Maggio';
            case 5:
                return 'Giugno';
            case 6:
                return 'Luglio';
            case 7:
                return 'Agosto';
            case 8:
                return 'Settembre';
            case 9:
                return 'Ottobre';
            case 10:
                return 'Novembre';
            case 11:
                return 'Dicembre';
        }
    };

    const selectWeek = (date) => {
        return Array(7).fill(new Date(date)).map((el, idx) =>
            new Date(el.setDate(el.getDate() - el.getDay() + idx)))
    }

    const handleStudioAppuntamentoConfirm = (idAppuntamento) => {
        let payload = {
            "idAppuntamento": idAppuntamento
        };
        wait(appuntamentiService.studioAppuntamentoConfirm(payload))
            .then(() => {
                setAlert({
                    'state': true,
                    'text': 'Appuntamento in studio confermato!',
                    'type': 'success'
                });
                handleAppuntamentiSearch();
            }).catch((err) => { handleError(err); });
    };

    const handleDomicilioAppuntamentoConfirm = (idAppuntamento) => {
        let payload = {
            "idDomiciliareAppuntamenti": idAppuntamento
        };
        wait(appuntamentiService.domicialiareAppuntamentoConfirm(payload))
            .then(() => {
                setAlert({
                    'state': true,
                    'text': 'Appuntamento a domicilio confermato!',
                    'type': 'success'
                });
                handleAppuntamentiSearch();
            }).catch((err) => { handleError(err); });
    };

    const handleStudioAppuntamentoCancel = (idAppuntamento, all) => {
        let payload = {
            "idAppuntamento": idAppuntamento
        };
        wait(appuntamentiService.studioAppuntamentoDelete(payload))
            .then((response) => {
                if(response.status === 202) {
                    setNotificationError(true);
                    listOfClientsWithNoNotification.push(
                        {
                            "nomeCognome": response.data.nomeECognome,
                            "telefono": response.data.telefono
                        })
                }
                if (!all) {
                    setAlert({
                        'state': true,
                        'text': 'Appuntamento in studio cancellato!',
                        'type': 'success'
                    });
                }
                else {
                    setAlert({
                        'state': true,
                        'text': 'Appuntamenti in studio cancellati!',
                        'type': 'success'
                    });
                }
                handleAppuntamentiSearch();
            }).catch((err) => { handleError(err); });
    };

    const handleDomicilioAppuntamentoCancel = (idAppuntamento, all) => {
        let payload = {
            "idDomiciliareAppuntamenti": idAppuntamento
        };
        wait(appuntamentiService.domicialiareAppuntamentoDelete(payload))
            .then((response) => {
                if(response.status === 202) {
                    listOfClientsWithNoNotification.push({
                            "nomeCognome": response.data.nomeECognome,
                            "telefono": response.data.telefono
                        })
                    setNotificationError(true);
                }
                if (!all) {
                    setAlert({
                        'state': true,
                        'text': 'Appuntamento a domicilio cancellato!',
                        'type': 'success'
                    });
                }
                else {
                    setAlert({
                        'state': true,
                        'text': 'Appuntamenti a domicilio cancellati!',
                        'type': 'success'
                    });
                }
                handleAppuntamentiSearch();
            }).catch((err) => { handleError(err); });
    };

    const handleTooltip = (id, stato) => {
        setTooltipAnnullaEConferma({
            "id": id,
            "statoAnnulla": (stato === "annulla") ? !tooltipAnnullaEConferma.statoAnnulla : tooltipAnnullaEConferma.statoAnnulla,
            "statoConferma": (stato === "conferma") ? !tooltipAnnullaEConferma.statoConferma : tooltipAnnullaEConferma.statoConferma
        })
    }

    const handleTooltipWeekDelete = (id) => {
        setAnnullaTuttiAppuntamentiTooltip({
            "id": id,
            "stato": !annullaTuttiAppuntamentiTooltip.stato
        })
    }

    const timeComparisonValidation = (oraDa, oraA) => {
        return (oraDa && oraA) && ((oraDa.slice(0, 2) < oraA.slice(0, 2)) || ((oraDa.slice(0, 2) === oraA.slice(0, 2)) && (oraDa.slice(3, 5) < oraA.slice(3, 5))));
    }

    const appuntamentoListRendering = (date) => {
        let today = new Date();
        let tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        let appuntamentoListTmp = [];
        appuntamentoList.map((appuntamento, index) => {
            if (appuntamento.data === formatDate(date)) {
                let disabledAnnulla = false;
                // let disabledAnnulla = (appuntamento.data <= formatDate(today) ||
                //     (appuntamento.data === formatDate(tomorrow) &&
                //         (timeComparisonValidation(appuntamento.oraDa, today.toTimeString().slice(0, 5)) ||
                //             timeComparisonValidation(appuntamento.oraA, today.toTimeString().slice(0, 5)))));
                appuntamentoListTmp.push(
                    <Row className={'m-0' + ((index == 0) ? ' ' : ' mt-3 ') + 'd-flex justify-content-center align-items-center'} style={{ backgroundColor: '#e6e6e6' }}>
                        <Col xs={10}>
                            <p className='m-0'><strong>{appuntamento.oraDa.slice(0, 5) + ' - ' + appuntamento.oraA.slice(0, 5)}</strong></p>
                            <p className='m-0'><small><strong>{((appuntamento.modalita === 'Studio') ? appuntamento.nomeStudio : appuntamento.modalita) + ' - ' + appuntamento.indirizzo}</strong></small></p>
                            <p className='m-0'><small>{appuntamento.cognomeAssistito + ' ' + appuntamento.nomeAssistito}</small></p>
                            <p className='m-0'><small>{appuntamento.nomeAppuntamento}</small></p>
                        </Col>
                        <Col xs={2} className='h-100'>
                            <Row className='d-flex align-items-end justify-content-center' style={{ marginBottom: '50%', marginTop: '50%' }}>
                                {!appuntamento.flgConferma &&
                                    <svg
                                        id={'conferma_' + appuntamento.idAppuntamento}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        class="bi bi-check-circle icon"
                                        viewBox="0 0 16 16"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setConfirmModal(true);
                                            setConfirmModalAppuntamento(appuntamento);
                                            setTipoModalita(appuntamento.modalita);
                                        }}
                                    >
                                        <g fill="#008758">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                        </g>
                                    </svg>
                                }
                                {appuntamento.flgConferma &&
                                    <svg
                                        id={'conferma_' + appuntamento.idAppuntamento}
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="bi bi-check-circle-fill icon"
                                        viewBox="0 0 16 16"
                                    >
                                        <g fill="#008758">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </g>
                                    </svg>
                                }
                                <Tooltip
                                    key={("tooltip" + index) + appuntamento.flgConferma}
                                    placement='top'
                                    isOpen={(tooltipAnnullaEConferma.id == appuntamento.idAppuntamento) ? tooltipAnnullaEConferma.statoConferma : false}
                                    target={'conferma_' + appuntamento.idAppuntamento}
                                    toggle={() => handleTooltip(appuntamento.idAppuntamento + "", "conferma")}
                                >
                                    {(appuntamento.flgConferma) ? "Appuntamento confermato" : "Conferma appuntamento"}
                                </Tooltip>
                            </Row>

                            <Row className='d-flex align-items-center justify-content-center' style={{ marginBottom: '50%', marginTop: '50%' }}>
                                {!disabledAnnulla && <svg
                                    id={'annulla_' + appuntamento.idAppuntamento}
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    className="bi bi-x-circle icon"
                                    viewBox="0 0 16 16"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setCancelModal(true);
                                        setCancelModalAppuntamento(appuntamento);
                                    }}
                                >
                                    <g fill="#d9364f">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </g>
                                </svg>}
                                {!disabledAnnulla && <Tooltip
                                    placement='top'
                                    isOpen={(tooltipAnnullaEConferma.id == appuntamento.idAppuntamento) ? tooltipAnnullaEConferma.statoAnnulla : false}
                                    target={'annulla_' + appuntamento.idAppuntamento}
                                    toggle={() => handleTooltip(appuntamento.idAppuntamento + "", "annulla")}
                                >
                                    Annulla appuntamento
                                </Tooltip>}
                            </Row>

                        </Col>
                    </Row>
                );
            }
        });
        return appuntamentoListTmp;
    };

    const handleCancelAll = (date) => {
        let all = false;
        if (appuntamentoList.length > 1) {
            all = true;
        }
        for (let i = 0; i < appuntamentoList.length; i++) {
            if (date === appuntamentoList[i].data) {
                if (appuntamentoList[i].modalita === 'Studio') {
                    handleStudioAppuntamentoCancel(appuntamentoList[i].idAppuntamento, all);
                } else {
                    handleDomicilioAppuntamentoCancel(appuntamentoList[i].idAppuntamento, all);
                }
            }
        }
        setCancelAllModalState(false);
        setCancelAllModalDate('');
    };

    const isAppuntamentiListEmpty = (giorno) => {
        let numeroAppuntamenti = 0;
        appuntamentoList.map((a) => {
            if (a.data === formatDate(giorno)) {
                numeroAppuntamenti++;
            }
        })
        if (numeroAppuntamenti > 0) return false
        else return true
    }

    const cardsRendering = () => {
        let weekDates = [];
        if (isSearchThroughArrows) {
            weekDates = selectWeek(inputValues.data);
        } else {
            weekDates = selectWeek(selectedWeeks);
        }
        weekDates.shift();
        weekDates.pop();
        let cards = [];
        for (let i = 0; i < 5; i++) {
            cards.push(
                <Col xs={12} sm={6} lg={3} xl={2}>
                    <Card spacing className='card-bg card-big no-after' style={{ height: '55vh', borderRadius: "20px", overflow: "hidden", }}>
                        <CardTitle
                            className='bg-primary text-white m-0'
                        >
                            <Row className='w-100 m-0' id={'card title ' + formatDate(weekDates[i])} style={{ height: '88px' }}>
                                <Col xs={2} className='d-flex justify-content-center' />
                                <Col xs={8} className='d-flex justify-content-center align-items-center text-center'>
                                    <p className='mt-3'><strong>{getItalianWeekDay(weekDates[i]) + ' ' + weekDates[i].getDate() + ' ' + getItalianMonthName(weekDates[i])}</strong></p>
                                </Col>
                                <Col xs={2} className='d-flex justify-content-center align-items-center'>
                                    <Icon
                                        id={'annulla_tutti_' + i}
                                        className='bg-primary'
                                        icon='it-close-circle'
                                        color='white'
                                        aria-hidden
                                        size='lg'
                                        style={{ cursor: (isAppuntamentiListEmpty(weekDates[i])) ? 'auto' : 'pointer' }}
                                        onClick={() => {
                                            if (!isAppuntamentiListEmpty(weekDates[i])) {
                                                setCancelAllModalState(true);
                                                setCancelAllModalDate(new Date(weekDates[i]));
                                            }
                                        }}
                                    />
                                    {!isAppuntamentiListEmpty(weekDates[i]) &&
                                        <Tooltip
                                            key={('annulla_tutti_' + i) + annullaTuttiAppuntamentiTooltip.stato}
                                            placement='top'
                                            isOpen={annullaTuttiAppuntamentiTooltip.id == i ? annullaTuttiAppuntamentiTooltip.stato : false}
                                            target={'annulla_tutti_' + i}
                                            toggle={() => handleTooltipWeekDelete(i)}
                                        >
                                            Annulla tutti gli appuntamenti
                                        </Tooltip>
                                    }
                                </Col>

                            </Row>
                        </CardTitle>
                        <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                            {appuntamentoListRendering(weekDates[i])}
                        </div>
                    </Card>
                </Col>
            );
        }
        return cards;
    };

    const handleMoveWeek = (kind) => {
        setIsSearchThroughArrows(true);
        let firstWeekDate = new Date(inputValues.data);
        let yesterdayDate = new Date(firstWeekDate.getFullYear(), firstWeekDate.getMonth(), ((kind === 0) ? firstWeekDate.getDate() - 7 : firstWeekDate.getDate() + 7));
        let event = {
            'target': {
                'value': formatDate(yesterdayDate)
            }
        };
        handleInputChange('data', event);
    };

    const [tipoModalita, setTipoModalita] = useState(null);

    return (
        <Col>
            <CustomBreadCrumb
                first={{
                    "name": "Elenco Appuntamenti",
                    "onClick": () => { },
                    "active": true
                }}
            />
            <span class='bi bi-check-circle' />
            <Row style={{ marginTop: '4vh' }}>
                <Col xs={12} sm={6} xl={3}>
                    <div
                        class='form-group'
                        onClick={() => {
                            setIsModalitaEnabled(true);
                            document.getElementById('select modalita').focus();
                        }}
                    >
                        <label
                            htmlFor='selectExampleClassic'
                            class={(isModalitaEnabled || inputValues.modalita != null) ? 'active' : ''}
                            style={{ cursor: 'context-menu' }}
                        >
                            Modalità</label>
                        <Select
                            id='selectExampleReset'
                            inputId='select modalita'
                            onBlur={() => { setIsModalitaEnabled(false); }}
                            isClearable={true}
                            placeholder=''
                            aria-label='Scegli una opzione'
                            isSearchable={false}
                            options={modalidaList}
                            onChange={(event) => { handleInputChange('modalita', event) }}
                            styles={customStylesInputSelect}
                            menuPortalTarget={document.body}
                            menuIsOpen={isModalitaEnabled}
                        />
                    </div>
                </Col>
                <Col xs={12} sm={6} xl={3}>
                    <div
                        class='form-group'
                        onClick={() => {
                            setIsStudioEnabled(true);
                            document.getElementById('select studio').focus();
                        }}
                    >
                        <label
                            htmlFor='selectExampleClassic'
                            class={(isStudioEnabled || inputValues.studio != null) ? 'active' : ''}
                            style={{ cursor: 'context-menu' }}
                        >
                            Studio</label>
                        <Select
                            id='selectExampleReset'
                            inputId='select studio'
                            onBlur={() => { setIsStudioEnabled(false); }}
                            isClearable={true}
                            placeholder=''
                            aria-label='Scegli una opzione'
                            isSearchable={false}
                            options={studioList}
                            onChange={(event) => { handleInputChange('studio', event) }}
                            styles={customStylesInputSelect}
                            menuPortalTarget={document.body}
                            menuIsOpen={isStudioEnabled}
                        />
                    </div>
                </Col>
                <Col xs={12} sm={6} xl={3}>
                    <div
                        class='form-group'
                        onClick={() => {
                            setIsMotivoEnabled(true);
                            document.getElementById('select motivo').focus();
                        }}
                    >
                        <label
                            htmlFor='selectExampleClassic'
                            class={(isMotivoEnabled || inputValues.motivo != null) ? 'active' : ''}
                            style={{ cursor: 'context-menu' }}
                        >
                            Motivo</label>
                        <Select
                            id='selectExampleReset'
                            inputId='select motivo'
                            onBlur={() => { setIsMotivoEnabled(false); }}
                            isClearable={true}
                            placeholder=''
                            aria-label='Scegli una opzione'
                            isSearchable={false}
                            options={motivoList}
                            onChange={(event) => { handleInputChange('motivo', event) }}
                            styles={customStylesInputSelect}
                            menuPortalTarget={document.body}
                            menuIsOpen={isMotivoEnabled}
                        />
                    </div>
                </Col>
                <Col xs={12} sm={6} xl={3}>
                    <CustomDateField
                        value={inputValues.data}
                        placeholder='Data'
                        onChange={(event) => { handleInputChange('data', event) }}
                    />
                </Col>
            </Row>
            <Row className='d-flex justify-content-end'>
                <Col xs={12} xl={2}>
                    <Button
                        className="buttonPrimary"
                        id='cerca_btn'
                        onClick={() => {
                            handleAppuntamentiSearch();
                            setAlert({
                                'state': false,
                                'text': '',
                                'type': ''
                            });
                        }}
                    >
                        CERCA
                    </Button>{' '}
                </Col>
                <Tooltip
                    placement='top'
                    isOpen={cercaTooltip}
                    target={'cerca_btn'}
                    toggle={() => setCercaTooltip(!cercaTooltip)}
                >
                    Cerca appuntamenti
                </Tooltip>
            </Row>

            <LinkList>
                <LinkListItem divider />
            </LinkList>

            {alert.type !== 'error' &&
                <Row>
                    <Col xl={1} className='d-flex justify-content-center'>
                        <span style={{ height: "48px" }} id='left_arrow'>
                            <Icon
                                className='left'
                                icon='it-chevron-left'
                                color='primary'
                                aria-hidden
                                size='lg'
                                style={{ cursor: 'pointer' }}
                                onClick={() => { handleMoveWeek(0) }}
                            />
                        </span>
                    </Col>
                    <Tooltip
                        placement='top'
                        isOpen={settimanaPrecedenteTooltip}
                        target={'left_arrow'}
                        toggle={() => setSettimanaPrecedenteTooltip(!settimanaPrecedenteTooltip)}
                    >
                        Settimana precedente
                    </Tooltip>
                    {alert.type !== 'error' && cardsRendering()}
                    <Modal
                        placement='top'
                        trigger='focus'
                        isOpen={cancelModal}
                    >
                        <div className='modal-content'>
                            <ModalHeader>
                                Annullamento appuntamento "{cancelModalAppuntamento.nomeAppuntamento}".
                            </ModalHeader>
                            <ModalBody>
                                Sei sicuro di voler annullare l'appuntamento di {() => { return ' ' }}
                                {getItalianWeekDay(new Date(cancelModalAppuntamento.data))}  {new Date(cancelModalAppuntamento.data).getDate()}  {getItalianMonthName(new Date(cancelModalAppuntamento.data))},
                                dalle ore {(cancelModalAppuntamento.oraDa) ? cancelModalAppuntamento.oraDa.slice(0, 5) : null} alle ore {(cancelModalAppuntamento.oraA) ? cancelModalAppuntamento.oraA.slice(0, 5) : null}?
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    className="buttonPrimary"
                                    onClick={() => { setCancelModal(false) }}
                                >
                                    Annulla
                                </Button>
                                <Button
                                    className="buttonPrimary"
                                    onClick={() => {
                                        if (cancelModalAppuntamento.modalita === 'Studio') {
                                            handleStudioAppuntamentoCancel(cancelModalAppuntamento.idAppuntamento, false);
                                        } else {
                                            handleDomicilioAppuntamentoCancel(cancelModalAppuntamento.idAppuntamento, false);
                                        }
                                        setCancelModal(false);
                                    }}
                                >
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal>
                    <Modal
                        placement='top'
                        trigger='focus'
                        isOpen={cancelAllModalState}
                    >
                        <div className='modal-content'>
                            <ModalHeader>
                                {((cancelAllModalDate !== '') ?
                                    'Annullamento appuntamenti di ' + getItalianWeekDay(cancelAllModalDate) + ' ' + new Date(cancelAllModalDate).getDate() + ' ' + getItalianMonthName(new Date(cancelAllModalDate)) + '.'
                                    : '')}
                            </ModalHeader>
                            <ModalBody>
                                {((cancelAllModalDate !== '') ?
                                    'Sei sicuro di voler annullare tutti gli appuntamenti di ' +
                                    getItalianWeekDay(cancelAllModalDate) + ' ' + new Date(cancelAllModalDate).getDate() + ' ' + getItalianMonthName(new Date(cancelAllModalDate)) + '?'
                                    : '')}
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    className="buttonPrimary"
                                    onClick={() => {
                                        setCancelAllModalState(false);
                                        setCancelAllModalDate('');
                                    }}
                                >
                                    Annulla
                                </Button>
                                <Button
                                    className="buttonPrimary"
                                    onClick={() => {
                                        handleCancelAll(formatDate(cancelAllModalDate))
                                    }}
                                >
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal>
                    <Modal
                        placement='top'
                        trigger='focus'
                        isOpen={confirmModal}
                    >
                        <div className='modal-content'>
                            <ModalHeader>
                                Conferma appuntamento "{confirmModalAppuntamento.nomeAppuntamento}".
                            </ModalHeader>
                            <ModalBody>
                                Sei sicuro di voler confermare l'appuntamento di {() => { return ' ' }}
                                {getItalianWeekDay(new Date(confirmModalAppuntamento.data))}  {new Date(confirmModalAppuntamento.data).getDate()}  {getItalianMonthName(new Date(confirmModalAppuntamento.data))},
                                dalle ore {(confirmModalAppuntamento.oraDa) ? confirmModalAppuntamento.oraDa.slice(0, 5) : null} alle ore {(confirmModalAppuntamento.oraA) ? confirmModalAppuntamento.oraA.slice(0, 5) : null}?
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    className="buttonPrimary"
                                    onClick={() => { setConfirmModal(false) }}
                                >
                                    Annulla
                                </Button>
                                <Button
                                    className="buttonPrimary"
                                    onClick={() => {
                                        if (tipoModalita === 'Studio') {
                                            handleStudioAppuntamentoConfirm(confirmModalAppuntamento.idAppuntamento);
                                        } else {
                                            handleDomicilioAppuntamentoConfirm(confirmModalAppuntamento.idAppuntamento);
                                        }
                                        setConfirmModal(false);
                                    }}
                                >
                                    Conferma
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal>
                    <Modal
                        placement='top'
                        trigger='focus'
                        isOpen={deleteError}
                    >
                        <div className='modal-content'>
                            <ModalHeader>Annullamento appuntamenti</ModalHeader>
                            <ModalBody>Non è possibile cancellare appuntamenti passati!</ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" onClick={() => { setDeleteError(false); }}>
                                    Annulla
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal>
                    <Modal
                        placement='top'
                        trigger='focus'
                        isOpen={notificationError}
                    >
                        <div className='modal-content'>
                            <ModalHeader>Annullamento appuntamenti</ModalHeader>
                            <ModalBody>Attenzione per problemi tecnici la notifica di annullo non è stata inviata 
                            {listOfClientsWithNoNotification.length > 1 ? " agli assistiti" : " all'assistito"}:
                            <br></br>
                            {listOfClientsWithNoNotification.map((e, index) => 
                                    <i> {e.nomeCognome} {e.telefono ? " " : null} {e.telefono} <br></br> </i>                                  
                            )}
                            {listOfClientsWithNoNotification.length > 1 ? "Si invita ad avvertire gli assistiti" : "Si invita ad avvertire l'assistito"}
                            </ModalBody>
                            <ModalFooter>
                                <Button className="buttonPrimary" 
                                onClick={() => { 
                                    setNotificationError(false);
                                    setListOfClientsWithNoNotification([]); 
                                }}>
                                    Annulla
                                </Button>
                            </ModalFooter>
                        </div>
                    </Modal>
                    <Col xl={1} className='d-flex justify-content-center'>
                        <span style={{ height: "48px" }} id='right_arrow'>
                            <Icon
                                className='right'
                                icon='it-chevron-right'
                                color='primary'
                                aria-hidden
                                size='lg'
                                style={{ cursor: 'pointer' }}
                                onClick={() => { handleMoveWeek(1) }}
                            />
                        </span>
                    </Col>
                    <Tooltip
                        placement='top'
                        isOpen={settimanaSuccessivaTooltip}
                        target={'right_arrow'}
                        toggle={() => setSettimanaSuccessivaTooltip(!settimanaSuccessivaTooltip)}
                    >
                        Settimana successiva
                    </Tooltip>
                </Row>
            }
            {alert.state &&
                <Alert
                    color={alert.type}
                >
                    {alert.text}
                </Alert>
            }
        </Col>
    );
}

export default AppuntamentiPage;