import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';

export const CookiePolicyModal = ({
    openDialog,
    toggleDialog
}) => {
    return (
        <Modal
            isOpen={openDialog}
            toggle={() => {
                toggleDialog(!openDialog)
            }}
            scrollable
            labelledBy='cookiePolicy'
            size='lg'
        >
            <ModalHeader id='cookiePolicy'>Cookie policy</ModalHeader>
            <ModalBody>
                <p>
                    Il Sito WEB utilizza solo Cookies Tecnici necessari per la gestione 
                    dell’autenticazione dell’utente. Navigando il sito, si accetta espressamente 
                    la nostra politica di utilizzo dei Cookies. 
                </p>
                 <h4>Cookies necessari:</h4>
                    <h5>Sempre attivi</h5>
                <p>
                    I cookie strettamente necessari permettono l'utilizzo di determinate funzioni,
                     senza le quali non è possibile utilizzare il nostro sito web come previsto. 
                     Questi cookie (First Party Cookies) sono esclusivamente utilizzati da noi 
                     e non sono riconducibili a te.
                </p>
                   <h4>Cookies tecnici:</h4>
                    <h5>Sempre attivi</h5>
                    <p>Questo tipo di Cookies permette di gestire l’autenticazione dell’utente loggato 
                    al Sito WEB garantendone la sicurezza delle operazioni.
                    </p>
                    <br></br>
                    <p>
                    È possibile bloccare i Cookies tramite la funzione presente nelle impostazioni del Vostro 
                    browser, che permetterà di rifiutare l’impostazione di tutti o di alcuni Cookies. 
                    Tuttavia, se vorrete bloccare tutti i Cookies (inclusi anche quelli strettamente necessari), 
                    potreste non riuscire poi ad accedere a tutti o ad alcuni contenuti presenti sul Sito.
                    {' '}</p>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center" }}>
                <Button
                    color='primary'
                    onClick={() => {
                        toggleDialog(!openDialog)
                    }}
                    size='md'
                >
                    Indietro
                </Button>
            </ModalFooter>
        </Modal>)
}