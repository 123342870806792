import { useKeycloak } from "@react-keycloak/web";
import { Button, Col, FormGroup, Icon, Modal, ModalBody, ModalFooter, ModalHeader, Row, Toggle } from 'design-react-kit';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import logo from '../../img/regione-sicilia-logo.svg';
import { PrivacyPolicyModal } from '../custom/PrivacyPolicyModal';
import durataAppuntamentoService from '../service/durataAppuntamentoService';
import medicoService from '../service/medicoService';
import { wait } from './../common/Loading';
import { TerminiECondizioniModal } from './TerminiECondizioniModal';

export const TermsAndConditionsModal = ({
    togglePopulateDurataAppuntamenti,
    termsAndConditionsModalIsOpen,
    toggleTermsAndConditions
}) => {
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const [errorPage, setErrorPage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [disabledAccetta, setDisabledAccetta] = useState(true);
    const [firstToggleControl, setFirstToggleControl] = useState(false);
    const [secondToggleControl, setSecondToggleControl] = useState(false);
    const [thirdToggleControl, setThirdToggleControl] = useState(false);
    const [openDialogPrivacyPolicy, setOpenDialogPrivacyPolicy] = useState(false);
    const [openDialogTerminiЕCondizioni, setOpenDialogTerminiЕCondizioni] = useState(false);

    useEffect(() => {
        if (firstToggleControl && secondToggleControl && thirdToggleControl) {
            setDisabledAccetta(false);
        } else {
            setDisabledAccetta(true);
        }
    }, [firstToggleControl, secondToggleControl, thirdToggleControl]);

    return (<>
        {termsAndConditionsModalIsOpen && <Modal
            key={termsAndConditionsModalIsOpen}
            className="termsAndConditionsModal"
            isOpen={true}
            toggle={() => { }}
        >
            <ModalHeader toggle={() => { }} id='esempio12'>
                <Icon icon={logo} />
                {!errorPage ? <h2>Termini e Condizioni Generali</h2>
                    : <h2>ATTENZIONE</h2>}
            </ModalHeader>
            <ModalBody>
                {!errorPage ?
                    <Row>
                        <Col sm='12'>
                            <FormGroup check className='m-3 termsAndConditionsToggle'>
                                <Col sm={7}>Sono d'accordo con i <a style={{ cursor: "pointer" }} onClick={() => setOpenDialogTerminiЕCondizioni(!openDialogTerminiЕCondizioni)}>Termini e Condizioni d'uso</a></Col>
                                <Col sm={2}><Toggle disabled={false} onClick={() => { setFirstToggleControl(!firstToggleControl); }} /></Col>
                            </FormGroup>
                        </Col>
                        <Col sm='12'>
                            <FormGroup check className='m-3 termsAndConditionsToggle'>
                                <Col sm={7}>Sono d'accordo con I' <a style={{ cursor: "pointer" }} onClick={() => setOpenDialogPrivacyPolicy(!openDialogPrivacyPolicy)}>Informativa sulla privacy</a></Col>
                                <Col sm={2}><Toggle disabled={false} onClick={() => { setSecondToggleControl(!secondToggleControl); }} /></Col>
                            </FormGroup>
                        </Col>
                        <Col sm='12'>
                            <FormGroup check className='m-3 termsAndConditionsToggle'>
                                <Col sm={9}>Aderisco al servizio di "Prenotazione Appuntamenti" per i miei assistiti</Col>
                                <Col sm={2}><Toggle disabled={false} onClick={() => { setThirdToggleControl(!thirdToggleControl); }} /></Col>
                            </FormGroup>
                        </Col>
                    </Row> : <Row className="modalBody">
                        <h4 className="errorMessage">{errorMessage}</h4>
                    </Row>}
            </ModalBody>
            {!errorPage ?
                <ModalFooter className="terminiECondizioniModalFooter">
                    <Button className="buttonPrimary" onClick={() => keycloak.logout()}>
                        Annulla
                    </Button>
                    <Button
                        className="buttonPrimary"
                        onClick={() => {
                            wait(medicoService.terminiCondizioni({}))
                                .then((resp) => {
                                    navigate("/");
                                    toggleTermsAndConditions(false);
                                    setFirstToggleControl(false);
                                    setSecondToggleControl(false);
                                    setThirdToggleControl(false);
                                    durataAppuntamentoService.validate({}).then((resp) => {
                                        if (!resp.data) {
                                            togglePopulateDurataAppuntamenti(true);
                                        }
                                    });
                                })
                                .catch((err) => {
                                    if (err.response && (err.response.status === 501 || err.response.status === 503)) {
                                        setErrorMessage(err.response.data);
                                        setErrorPage(true);
                                    } else {
                                        setErrorMessage("Si è verificato un errore");
                                        setErrorPage(true);
                                    }
                                })
                        }}
                        disabled={disabledAccetta}>
                        Accetta
                    </Button>
                </ModalFooter> :
                <ModalFooter className="errorModalFooter">
                    <Button className="buttonPrimary esciButton" onClick={() => keycloak.logout()}>
                        Esci
                    </Button>
                </ModalFooter>
            }
        </Modal>
        }
        {openDialogPrivacyPolicy && (
            <PrivacyPolicyModal
                openDialog={openDialogPrivacyPolicy}
                toggleDialog={(value) => setOpenDialogPrivacyPolicy(value)}
            />
        )}
        {openDialogTerminiЕCondizioni && (
            <TerminiECondizioniModal
                openDialog={openDialogTerminiЕCondizioni}
                toggleDialog={(value) => setOpenDialogTerminiЕCondizioni(value)}
            />
        )}
    </>)
}

