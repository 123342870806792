import { Button, Col, Container, DropdownMenu, DropdownToggle, Icon, LinkList, LinkListItem, Row, UncontrolledDropdown } from 'design-react-kit';
import {useKeycloak} from "@react-keycloak/web";


export const UserAvatar = (props) => {
    const { keycloak } = useKeycloak();

    return (<Container>
            <Row>
              <UncontrolledDropdown
                  nav
                  tag="div"
              >
                  <DropdownToggle
                  caret
                  nav
                  className="dropdown-style"
                  >
                  <Button
                      className="btn-icon"
                      color="primary"
                      href="#"
                      size="full"
                      >
                      <span className="rounded-icon">
                          <Icon
                          color="primary"
                          icon="it-user"
                          />
                      </span>
                      <span className="d-none d-lg-block">
                          {`${keycloak.idTokenParsed.given_name} ${keycloak.idTokenParsed.family_name}`}
                      </span>
                      <Icon
                      className="d-none d-lg-block arrow"
                      color="icon-white"
                      icon="it-expand"                      
                  />
                  </Button>
                  
                  </DropdownToggle>
                  <DropdownMenu>
                    <Row>
                      <Col size="12">
                        <LinkList>
                          <LinkListItem
                            href="#"
                            className="dropdown-element-style"
                            onClick={() => console.log("User profile click")}
                          >
                          <span>
                            Profile
                          </span>
                          </LinkListItem>
                          <LinkListItem
                            href="#"
                            className="dropdown-element-style"
                            onClick={() => keycloak.logout()}
                          >
                          <span>
                              Log out
                          </span>
                          </LinkListItem>
                        </LinkList>
                      </Col>
                    </Row>
                  </DropdownMenu>
              </UncontrolledDropdown>
            </Row>
           </Container>
    )
}




