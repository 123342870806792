import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import { Button, Card, CardBody, CardText, CardTitle, Col, Icon, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'design-react-kit';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import 'typeface-lora';
import 'typeface-roboto-mono';
import 'typeface-titillium-web';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';
import durataAppuntamentoService from '../service/durataAppuntamentoService';
import studioService from '../service/studioService';
import { wait } from './../common/Loading';

function GestioneStudiMediciPage(
    { toggleTermsAndConditions,
        togglePopulateDurataAppuntamenti }) {

    const [listaStudiInfo, setListaStudiInfo] = useState([]);
    const targetRef = useRef(null);
    const navigate = useNavigate();
    const [deleteError, setDeleteError] = useState(false);

    const handleError = (err) => {
        if (err.response && err.response.status === 512) {
            toggleTermsAndConditions(true);
        } else if (err.response && (err.response.status === 500 && err.response.data === "Studio can not be deleted. There are appointments available for this studio.")) {
            setDeleteError(true);
        }
    }

    const getListaStudi = () => {
        let studiListTmp = [];

        wait(studioService.searchListaStudi({}))
            .then((resp) => {
                resp.data.forEach((studio, index) => {
                    studiListTmp.push({
                        "key": index,
                        "codFiscale": studio.codFiscale,
                        "codIstatComune": studio.codIstatComune,
                        "idStudio": studio.idStudio,
                        "indirizzo": studio.indirizzo,
                        "nomeStudio": studio.nomeStudio,
                        "nomeComuneItaliano": studio.nomeComuneItaliano,
                        "cap": studio.cap,
                        "mail": studio.mail,
                        "telefonoFisso": studio.telefonoFisso,
                        "telefonoLavoro": studio.telefonoLavoro,
                        "telefonoMobile": studio.telefonoMobile,
                        "popupEliminazione": false,
                        "gioMattinaDa": studio.gioMattinaDa,
                        "gioMattinaA": studio.gioMattinaA,
                        "gioPomeriggioDa": studio.gioPomeriggioDa,
                        "gioPomeriggioA": studio.gioPomeriggioA,
                        "lunMattinaDa": studio.lunMattinaDa,
                        "lunMattinaA": studio.lunMattinaA,
                        "lunPomeriggioDa": studio.lunPomeriggioDa,
                        "lunPomeriggioA": studio.lunPomeriggioA,
                        "marMattinaDa": studio.marMattinaDa,
                        "marMattinaA": studio.marMattinaA,
                        "marPomeriggioDa": studio.marPomeriggioDa,
                        "marPomeriggioA": studio.marPomeriggioA,
                        "merMattinaDa": studio.merMattinaDa,
                        "merMattinaA": studio.merMattinaA,
                        "merPomeriggioDa": studio.merPomeriggioDa,
                        "merPomeriggioA": studio.merPomeriggioA,
                        "venMattinaDa": studio.venMattinaDa,
                        "venMattinaA": studio.venMattinaA,
                        "venPomeriggioDa": studio.venPomeriggioDa,
                        "venPomeriggioA": studio.venPomeriggioA
                    });
                });
                setListaStudiInfo(studiListTmp);
            }).catch((err) => {
                handleError(err);
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getListaStudi();
    }, []);

    const toggleModal = (studio) => {
        const listaTmp = [];
        listaStudiInfo.map((s) => {
            listaTmp.push(s);
        })
        listaTmp.map((s) => {
            if (s.idStudio === studio.idStudio) {
                s.popupEliminazione = !studio.popupEliminazione
            }
        })
        setListaStudiInfo(listaTmp);
    };

    const updateStudio = (studio) => {
        durataAppuntamentoService.validate({}).then((resp) => {
            if (resp.data) {
                navigate("/modify-studi-medici", {
                    state: {
                        idStudio: studio.idStudio
                    }
                });
            } else {
                togglePopulateDurataAppuntamenti(true);
            }
        });

    };

    const insertStudio = () => {
        durataAppuntamentoService.validate({}).then((resp) => {
            if (resp.data) {
                navigate("/modify-studi-medici");
            } else {
                togglePopulateDurataAppuntamenti(true);
            }
        });
    }

    const deleteStudio = (idStudio) => {
        wait(studioService.deleteStudio({ "idStudio": idStudio })).then(() => {
            getListaStudi();
        }).catch((err) => {
            handleError(err);
        })
    }

    const formatTime = (time) => {
        const timeArray = time.split(":");
        return timeArray[0] + ":" + timeArray[1];
    }

    const checkOrario = (oraMattinaDa, oraMattinaA, oraPomeriggioDa, oraPomeriggioA, nomeGiorno) => {
        const mattina = (
            ((oraMattinaDa !== null || oraMattinaA !== null) &&
                <>{formatTime(oraMattinaDa)}-{formatTime(oraMattinaA)}</>)
        )
        const pomeriggio = (
            ((oraPomeriggioDa !== null || oraPomeriggioA !== null) &&
                <div style={{ marginLeft: "40px" }}>{formatTime(oraPomeriggioDa)}-{formatTime(oraPomeriggioA)}</div>)
        )
        const orari = (mattina || pomeriggio) ? (<>{nomeGiorno}: {mattina}<br />{pomeriggio}</>) : null;
        return (<div style={{ lineHeight: "22px" }}>{orari}</div>);
    }

    const displayOrari = (studio) => {
        if (studio) {
            const lun = checkOrario(studio.lunMattinaDa, studio.lunMattinaA, studio.lunPomeriggioDa, studio.lunPomeriggioA, "Lun");
            const mar = checkOrario(studio.marMattinaDa, studio.marMattinaA, studio.marPomeriggioDa, studio.marPomeriggioA, "Mar");
            const mer = checkOrario(studio.merMattinaDa, studio.merMattinaA, studio.merPomeriggioDa, studio.merPomeriggioA, "Mer");
            const gio = checkOrario(studio.gioMattinaDa, studio.gioMattinaA, studio.gioPomeriggioDa, studio.gioPomeriggioA, "Gio");
            const ven = checkOrario(studio.venMattinaDa, studio.venMattinaA, studio.venPomeriggioDa, studio.venPomeriggioA, "Ven");

            return (<div key={"orario" + studio.key} style={{ marginTop: "-16px" }}>{lun}{mar}{mer}{gio}{ven}</div>)
        }
        return null;
    }

    return (
        <Container>
            <CustomBreadCrumb
                first={{
                    "name": "Elenco Studi Medici",
                    "onClick": () => { },
                    "active": true
                }}
            />
            <Row style={{ "marginBottom": "5%", "marginTop": "1%" }}>
                {listaStudiInfo.map((studio) => {
                    return (
                        <Col xs={12} sm={6} lg={3} xl={3}>
                            <Card style={{
                                borderRadius: "20px", overflow: "hidden", height: "60vh",
                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", marginTop: "4%"
                            }}>
                                <CardTitle style={{ backgroundColor: "#0066cc", color: "#fff", height: '88px' }}>
                                    <Row className='w-100 m-0 d-flex justify-content-center align-items-center' style={{ height: '88px' }}>
                                        <p className='mt-3'><strong>{studio.nomeStudio}</strong></p>
                                    </Row>
                                </CardTitle>
                                <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                                    <CardBody style={{ color: "#115287" }}>
                                        <Button size='xs' onClick={() => { updateStudio(studio) }}>
                                            <Icon icon="it-pencil" color="white" style={{ backgroundColor: "#0066cc", borderRadius: "5px" }}></Icon>
                                        </Button>
                                        <Button id={studio.idStudio} size='xs' onClick={() => { toggleModal(studio) }} innerRef={targetRef}>
                                            <Icon icon="it-minus" color="white" style={{ backgroundColor: "#0066cc", borderRadius: "5px" }}></Icon>
                                        </Button>
                                        <Modal
                                            placement='top'
                                            trigger='focus'
                                            target={targetRef}
                                            isOpen={(document.activeElement === document.getElementById(studio.idStudio)) ? true : false}
                                        >
                                            <div className='modal-content'>
                                                <ModalHeader>Eliminazione {studio.nomeStudio}</ModalHeader>
                                                <ModalBody>Sei sicuro di voler eliminare lo studio {studio.nomeStudio}?</ModalBody>
                                                <ModalFooter>
                                                    <Button className="buttonPrimary" onClick={() => { toggleModal(studio) }}>
                                                        Annulla
                                                    </Button>
                                                    <Button className="buttonPrimary" onClick={() => { deleteStudio(studio.idStudio) }}>
                                                        Conferma
                                                    </Button>
                                                </ModalFooter>
                                            </div>
                                        </Modal>
                                        <div style={{ backgroundColor: "#f2f2f2", marginBottom: "5%", height: "48%" }}>
                                            <CardTitle style={{ fontWeight: "bold", color: "#0066cc", marginLeft: "2%" }}>Orari</CardTitle>
                                            <CardText style={{ fontSize: "16px", marginLeft: "4%" }}>
                                                {
                                                    displayOrari(studio)
                                                }
                                            </CardText>
                                        </div>
                                        <div style={{ backgroundColor: "#f2f2f2", marginBottom: "5%", height: "15%" }}>
                                            <CardTitle style={{ fontWeight: "bold", color: "#0066cc", marginLeft: "2%" }}>Indirizzo</CardTitle>
                                            <CardText style={{ fontSize: "16px", marginTop: "-16px", lineHeight: "24px", marginLeft: "4%" }}>
                                                {studio.indirizzo}
                                                <br />
                                                {studio.nomeComuneItaliano.charAt(0) + studio.nomeComuneItaliano.substring(1).toLowerCase()}
                                            </CardText>
                                        </div>
                                        <div style={{ backgroundColor: "#f2f2f2", height: "20%" }}>
                                            <CardTitle style={{ fontWeight: "bold", color: "#0066cc", marginLeft: "2%" }}>Riferimenti</CardTitle>
                                            <CardText style={{ fontSize: "16px", marginTop: "-16px", lineHeight: "22px", marginLeft: "4%" }}>
                                                {studio.telefonoFisso !== null && <>Tel. {studio.telefonoFisso}<br /></>}
                                                {studio.telefonoMobile !== null && <>Cell. {studio.telefonoMobile}<br /></>}
                                                {studio.mail != null && <>Email {studio.mail}</>}
                                            </CardText>
                                        </div>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    )
                })}
                <Modal
                    placement='top'
                    trigger='focus'
                    isOpen={deleteError}
                >
                    <div className='modal-content'>
                        <ModalHeader>Eliminazione</ModalHeader>
                        <ModalBody>Non è possibile cancellare lo Studio in quanto sono presenti appuntamenti!</ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => { setDeleteError(false); }}>
                                Annulla
                            </Button>
                        </ModalFooter>
                    </div>
                </Modal>
                <Col xs={12} sm={8} lg={6} xl={4}>
                    <Button onClick={() => { insertStudio() }} style={{ marginTop: "4%", padding: "0" }}>
                        <Icon icon="it-plus" color="white" style={{ backgroundColor: "#0066cc", borderRadius: "5px" }}></Icon>
                    </Button>
                </Col>
            </Row>
        </Container>

    )
}

export default GestioneStudiMediciPage;