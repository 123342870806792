import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import {
  Collapse,
  Header,
  HeaderBrand,
  HeaderContent,
  HeaderRightZone,
  Headers,
  HeaderToggler,
  Icon,
  Nav,
  NavItem,
  NavLink,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  Button,
} from "design-react-kit";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "typeface-lora";
import "typeface-roboto-mono";
import "typeface-titillium-web";
import "../../App.css";
import logo from "../../img/regione-sicilia-logo.svg";
import { UserAvatar } from "../custom/UserAvatar";
import { useKeycloak } from "@react-keycloak/web";

function CustomHeader({ isPageChanged, setIsPageChanged }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isRequestedATabChange, toggleModalTabChange] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };

  const { keycloak } = useKeycloak();

  const [navigateTo, setNavigateTo] = useState(null);

  return (
    <Headers>
      <div className="it-nav-wrapper">
        <Header small theme="" type="center">
          <HeaderContent>
            <HeaderBrand iconName={logo}>
              <h2>Agende Appuntamenti Assisiti</h2>
              <h3>Regione Sicilia</h3>
            </HeaderBrand>
            <HeaderRightZone>{keycloak.authenticated && <UserAvatar />}</HeaderRightZone>
          </HeaderContent>
        </Header>
        <Header theme="" type="navbar">
          <HeaderContent expand="lg" megamenu>
            <HeaderToggler aria-controls="nav1" aria-label="Toggle navigation" onClick={toggle} aria-expanded={collapse}>
              <Icon icon="it-burger" />
            </HeaderToggler>
            <Collapse header navbar onOverlayClick={toggle} isOpen={collapse}>
              <div className="menu-wrapper">
                <Nav navbar>
                  <NavItem>
                    <NavLink
                      as={Link}
                      to="/appuntamenti"
                      active={location.pathname === "/appuntamenti"}
                      onClick={() => {
                        if ((location.pathname === "/modify-studi-medici" || location.pathname === "/gestione-visite-domicilio") && isPageChanged) {
                          setNavigateTo("/appuntamenti");
                          toggleModalTabChange(!isRequestedATabChange);
                        } else {
                          navigate("/appuntamenti");
                          setIsPageChanged(false);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span>Appuntamenti </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={location.pathname === "/studi-medico" || location.pathname === "/modify-studi-medici"}
                      onClick={() => {
                        if (location.pathname === "/gestione-visite-domicilio" && isPageChanged) {
                          setNavigateTo("/studi-medico");
                          toggleModalTabChange(!isRequestedATabChange);
                        } else if (location.pathname !== "/modify-studi-medici") {
                          navigate("/studi-medico");
                          setIsPageChanged(false);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span>Studi Medici</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={location.pathname === "/gestione-visite-domicilio"}
                      onClick={() => {
                        if (location.pathname === "/modify-studi-medici" && isPageChanged) {
                          setNavigateTo("/gestione-visite-domicilio");
                          toggleModalTabChange(!isRequestedATabChange);
                        } else {
                          navigate("/gestione-visite-domicilio");
                          setIsPageChanged(false);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span>Visite Domicilio</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      active={location.pathname === "/gestione-durata-appuntamenti"}
                      onClick={() => {
                        if (location.pathname === "/modify-studi-medici" && isPageChanged) {
                          setNavigateTo("/gestione-durata-appuntamenti");
                          toggleModalTabChange(!isRequestedATabChange);
                        } else {
                          navigate("/gestione-durata-appuntamenti");
                          setIsPageChanged(false);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span>Durata Appuntamenti</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Collapse>
          </HeaderContent>
        </Header>
        <Modal isOpen={isRequestedATabChange} toggle={() => toggleModalTabChange(!isRequestedATabChange)}>
          <ModalHeader toggle={() => toggleModalTabChange(!isRequestedATabChange)} id="esempio12">
            Indietro
          </ModalHeader>
          <ModalBody>
            <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
          </ModalBody>
          <ModalFooter>
            <Button className="buttonPrimary" onClick={() => toggleModalTabChange(!isRequestedATabChange)}>
              Annulla
            </Button>
            <Button
              className="buttonPrimary"
              onClick={() => {
                navigate(navigateTo);
                toggleModalTabChange(!isRequestedATabChange);
                setIsPageChanged(false);
              }}
            >
              Conferma
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Headers>
  );
}

export default CustomHeader;
