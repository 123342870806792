import { useState } from "react";

export const CustomDateField = (props) => {
    const [label, setLabel] = useState(false);

    return (
        <input
            type={(!label && !props.value) ? "text" : "date"}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget) && (event.currentTarget.value == null || event.currentTarget.value == "")) {
                    setLabel(false)
                }
            }}
            onFocus={() => { setLabel(true) }}
        />
    )
}




