import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const studioList = payload => {
    return axios.post(`${BASE_ENDPOINT}/studi-medico/search`, payload);
};

const motivoList = () => {
    return axios.post(`${BASE_ENDPOINT}/tipo-appuntamento/search`, {});
};

const appuntamentiSearch = payload => {
    return axios.post(`${BASE_ENDPOINT}/appuntamenti/search`, payload);
};

const studioAppuntamentoConfirm = payload => {
    return axios.post(`${BASE_ENDPOINT}/studio-appuntamenti/confirm`, payload);
};

const domicialiareAppuntamentoConfirm = payload => {
    return axios.post(`${BASE_ENDPOINT}/domiciliare-appuntamenti/confirm`, payload);
};

const studioAppuntamentoDelete = payload => {
    return axios.post(`${BASE_ENDPOINT}/studio-appuntamenti/delete`, payload);
};

const domicialiareAppuntamentoDelete = payload => {
    return axios.post(`${BASE_ENDPOINT}/domiciliare-appuntamenti/delete`, payload);
};

export default {
    studioList,
    motivoList,
    appuntamentiSearch,
    studioAppuntamentoConfirm,
    domicialiareAppuntamentoConfirm,
    studioAppuntamentoDelete,
    domicialiareAppuntamentoDelete
};