import durataAppuntamentiService from "../service/durataAppuntamentiService";
import { wait } from "./../common/Loading";
import React, { useState, useEffect, useRef } from "react";
import { Accordion, AccordionBody, Col, Icon, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardTitle, CardBody, CardText, Alert, Container } from "design-react-kit";

import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';
import { CustomTimeField } from "../custom/CustomTimeField";
import { useNavigate, useLocation } from "react-router-dom";

function DurataAppuntamentiPage({ isPageChanged, setIsPageChanged }) {
  const [hasLoaded, setHasLoaded] = useState();
  const [listaTipoDurataAppuntamento, setListaTipoDurataAppuntamenti] = useState([]);
  const navigate = useNavigate();
  const [messageFromRequest, setMessageFromRequest] = useState({
    state: false,
    text: "",
    type: "",
  });
  const [isSalvaClicked, setIsSalvaClicked] = useState(false);

  const getListaTipoDurataAppuntamenti = () => {
    let listaTipoDurataAppuntamentiTmp = [];
    wait(durataAppuntamentiService.searchListaTipoAppuntamento()).then((resp) => {
      resp.data.forEach(tipo => {
        listaTipoDurataAppuntamentiTmp.push({
          idTipoAppuntamento: tipo.idTipoAppuntamento,
          nomeAppuntamento: tipo.nomeAppuntamento,
          flgStudio: tipo.flgStudio,
          flgDomicilio: tipo.flgDomicilio,
          flgVisibile: tipo.flgVisibile,
          idDurataAppuntamento: null,
          idMedico: null,
          durataVisita: null,
        })
        wait(durataAppuntamentiService.searchListaTipoDurataAppuntamento()).then((resp) => {
          resp.data.forEach((tipoDurataAppuntamento) => {
            listaTipoDurataAppuntamentiTmp.forEach((tipo) => {
              if (tipo.idTipoAppuntamento === tipoDurataAppuntamento.idTipoAppuntamento) {
                tipo.idDurataAppuntamento = tipoDurataAppuntamento.idDurataAppuntamento;
                tipo.idMedico = tipoDurataAppuntamento.idMedico;
                tipo.durataVisita = tipoDurataAppuntamento.durataVisita;
              }
            })
          });
          setDefaultValuesForEachTipo(listaTipoDurataAppuntamentiTmp);
          setListaTipoDurataAppuntamenti(listaTipoDurataAppuntamentiTmp);
        })
      });
    }).catch((err) => {
      setMessageFromRequest({
        'state': true,
        'text': err.response ? err.response.data : "Errore nel caricamento dei dati.",
        'type': 'danger'
      });
    });
  };

  const defaultTypesAndDurations = [
    {
      "type": "Vaccinazione",
      "duration": "00:30:00"
    },
    {
      "type": "Medicazione",
      "duration": "00:30:00"
    },
    {
      "type": "Certificato",
      "duration": "00:15:00"
    },
    {
      "type": "Visita",
      "duration": "00:30:00"
    },
    {
      "type": "Prescrizione",
      "duration": "00:15:00"
    }
  ];

  const setDefaultValuesForEachTipo = (listaTipoDurataAppuntamentiTmp) => {
    listaTipoDurataAppuntamentiTmp.forEach(tipo => {
      defaultTypesAndDurations.forEach(defaultValues => {
        if (tipo.nomeAppuntamento === defaultValues.type && (tipo.durataVisita === "00:00:00" || tipo.durataVisita === null)) {
          tipo.durataVisita = defaultValues.duration;
        }
      })
    });
  }

  const handleChangeDurataTipoAppuntamento = (inputField, value, index) => {
    setIsPageChanged(true);
    setHasLoaded(false);
    listaTipoDurataAppuntamento[index].isChanged = true;
    if (inputField === "durataVisita") {
      listaTipoDurataAppuntamento[index].durataVisita = value;
    }
    setListaTipoDurataAppuntamenti([...listaTipoDurataAppuntamento]);
  };

  const requiredFielldLabel = "Il campo è obbligatiorio";

  useEffect(() => {
    window.scrollTo(0, 0);
    getListaTipoDurataAppuntamenti();
  }, []);

  const [isOpenSalva, toggleModalSalva] = useState(false);

  const areListaTipoDurataAppuntamentoPopulated = () => {
    for (let i = 0; i < listaTipoDurataAppuntamento.length; ++i) {
      if (listaTipoDurataAppuntamento[i].durataVisita === null || listaTipoDurataAppuntamento[i].durataVisita === "00:00:00") {
        return false;
      }
    }
    return true;
  };

  const salvaClick = () => {
    setMessageFromRequest({
      state: false,
      text: "",
      type: "",
    });

    let isValidInput = true;
    if (areListaTipoDurataAppuntamentoPopulated() == false) {
      setIsSalvaClicked(true);
      isValidInput = false;
    }
    if (isValidInput) {
      setIsSalvaClicked(true);
      let payloadForUpdate;
      payloadForUpdate = listaTipoDurataAppuntamento.map((elem) => {
        return {
          idDurataAppuntamento: elem.idDurataAppuntamento,
          idMedico: elem.idMedico,
          idTipoAppuntamento: elem.idTipoAppuntamento,
          durataVisita: elem.durataVisita,
        };
      });

      wait(durataAppuntamentiService.modifyDurateAppuntamenti(payloadForUpdate))
        .then((resp) => {
          getListaTipoDurataAppuntamenti();
          setMessageFromRequest({
            'state': true,
            'text': 'Durata Appuntamenti salvata correttamente!',
            'type': 'success'
          })
        }).catch((err) => {
          setMessageFromRequest({
            'state': true,
            'text': err.response ? err.response.data : "Errore nel salvataggio.",
            'type': 'danger'
          });
        });
    }
  };
  const confirmSalvaClick = () => {
    toggleModalSalva(!isOpenSalva);
    salvaClick();
  };
  const reloadList = () => {
    setTimeout(getListaTipoDurataAppuntamenti(), 3000);
  };

  return (
    <Container fluid>
      <CustomBreadCrumb
        first={{
          "name": "Gestione Durata Appuntamenti",
          "onClick": () => { },
          "active": true
        }}
      />
      <Row className="rowStyling">
        {listaTipoDurataAppuntamento.map((tipoDurataAppuntamento, index) => {
          return (
            <Col xs={12} sm={6} md={6} lg={4} xl={2}>
              <Container className="daysOfTheWeekSizing">
                <Row>
                  <Col>
                    <h6 className="mt-2 mb-2 text-secondary">{tipoDurataAppuntamento.nomeAppuntamento}</h6>
                    {tipoDurataAppuntamento.flgStudio == true && tipoDurataAppuntamento.flgDomicilio == true ? (
                      <h6 className="mt-2 mb-2 text-secondary"> (erogabile in Studio e Domicilio) </h6>
                    ) : tipoDurataAppuntamento.flgStudio == true && tipoDurataAppuntamento.flgDomicilio == false ? (
                      <h6 className="mt-2 mb-2 text-secondary"> (erogabile solo in Studio) </h6>
                    ) : (
                      <h6 className="mt-2 mb-2 text-secondary"> (erogabile solo in Domicilio) </h6>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <CustomTimeField
                      key={tipoDurataAppuntamento.durataVisita}
                      placeholder="durata"
                      value={tipoDurataAppuntamento.durataVisita}
                      onChange={(value) => {
                        handleChangeDurataTipoAppuntamento("durataVisita", value, index);
                      }}
                    />
                    {isSalvaClicked && (tipoDurataAppuntamento.durataVisita === null || tipoDurataAppuntamento.durataVisita == "00:00:00") && (
                      <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          );
        })}
      </Row>

      <br></br>
      <Container fluid style={{ marginBottom: "3%" }}>
        {(messageFromRequest.state) && <Alert color={messageFromRequest.type} className='display-linebreak'>{messageFromRequest.text}</Alert>}
        <Row style={{ display: "flex", justifyContent: "end" }}>
          <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
            <Button className="buttonPrimary" onClick={() => toggleModalSalva(!isOpenSalva)} disabled={messageFromRequest.type === "danger"}>
              SALVA
            </Button>
            <Modal isOpen={isOpenSalva} toggle={() => toggleModalSalva(!isOpenSalva)}>
              <ModalHeader toggle={() => toggleModalSalva(!isOpenSalva)} id="esempio12">
                CONFERMA
              </ModalHeader>
              <ModalBody>
                <p>Confermi il salvataggio delle modifiche?</p>
              </ModalBody>
              <ModalFooter>
                <Button className="buttonPrimary" onClick={() => toggleModalSalva(!isOpenSalva)}>
                  Annulla
                </Button>
                <Button className="buttonPrimary" onClick={confirmSalvaClick}>
                  Conferma
                </Button>
              </ModalFooter>
            </Modal>{" "}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default DurataAppuntamentiPage;
