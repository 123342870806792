import {
    Accordion, AccordionHeader, AccordionBody, Col, Container, Button, Row, Input, Icon, Select,
    UncontrolledTooltip, Alert, ModalFooter, ModalBody, ModalHeader, Modal
} from 'design-react-kit';
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import studioService from '../service/studioService';
import durataAppuntamentoService from '../service/durataAppuntamentoService';
import { wait } from './../common/Loading';
import { CODICE_FISCALE_REGEX, EMAIL_REGEX, HHMM_TIME_FORMAT, CAP_REGEX, PHONE_REGEX } from "../constants/regexConstants";

import '../../App.css';
import studiMediciService from '../service/studiMediciService';
import { CustomDateField } from '../custom/CustomDateField';
import { CustomTimeField } from '../custom/CustomTimeField';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';

function ModifyStudiMediciPage({
    isPageChanged,
    setIsPageChanged,
    toggleTermsAndConditions,
    togglePopulateDurataAppuntamenti }) {

    const navigate = useNavigate();
    const location = useLocation();

    const [errorMessage, setErrorMessage] = useState(null);

    const [collapseOpen1, setCollapseOpen1] = useState(true);
    const [collapseOpen2, setCollapseOpen2] = useState(true);
    const [collapseOpen3, setCollapseOpen3] = useState(true);
    const [collapseOpen4, setCollapseOpen4] = useState(true);

    const [isSalvaClicked, setIsSalvaClicked] = useState(false);
    const [showSostitutiRequirements, setShowSostitutiRequirements] = useState(false);
    const [showAnnullamentiRequirements, setShowAnnullamentiRequirements] = useState(false);

    const [keyForSostitutiInput, setKeyForSostitutiInput] = useState({ "key": 0 });
    const [sostitutiList, setSostitutiList] = useState([]);

    const [keyForAnnullamentiInput, setKeyForAnnullamentiInput] = useState({ "key": 0 });
    const [annullamentoOrariStudioList, setAnnullamentoOrariStudioList] = useState([]);

    const [comuniLista, setComuniLista] = useState([]);
    const [labelComune, setLabelComune] = useState(false);
    const [inputComune, setInputComune] = useState([]);

    const [anagrafica, setAnagrafica] = useState({
        "idStudio": null,
        "nomeStudio": null,
        "indirizzo": null,
        "cap": null,
        "codIstatComune": "",
        "telefonoLavoro": null,
        "telefonoMobile": null,
        "telefonoFisso": null,
        "mail": null,
        "nomeComune": ""
    });
    const [requiredNomeStudio, setRequiredNomeStudio] = useState(true);

    const [isValidCodIstatComune, setIsValidCodIstatComune] = useState(false);
    const [isValidTelefono, setIsValidTelefono] = useState(true);
    const [isValidCellulare, setIsValidCellulare] = useState(true);
    const [isValidMail, setIsValidMail] = useState(true);
    const [isValidCap, setIsValidCap] = useState(true);

    const [orariStudio, setOrariStudio] = useState({
        "idStudio": null,
        "lunMattinaDa": null,
        "lunMattinaA": null,
        "lunPomeriggioDa": null,
        "lunPomeriggioA": null,
        "marMattinaDa": null,
        "marMattinaA": null,
        "marPomeriggioDa": null,
        "marPomeriggioA": null,
        "merMattinaDa": null,
        "merMattinaA": null,
        "merPomeriggioDa": null,
        "merPomeriggioA": null,
        "gioMattinaDa": null,
        "gioMattinaA": null,
        "gioPomeriggioDa": null,
        "gioPomeriggioA": null,
        "venMattinaDa": null,
        "venMattinaA": null,
        "venPomeriggioDa": null,
        "venPomeriggioA": null
    })

    const [isComunePopulated, setIsComunePopulated] = useState(false);

    const handleError = (err) => {
        if (err.response && err.response.status === 512) {
            toggleTermsAndConditions(true);
        }
    }

    const [breadcrumbTitle, setBreadCrumbTite] = useState("");
    useEffect(() => {
        location.state ? setBreadCrumbTite("Modifica Studio") : setBreadCrumbTite("Insertimento Studio")
    }, [])

    useEffect(() => {
        if (location.state) {
            Promise.all([
                wait(studioService.searchStudioById(location.state.idStudio)),
                wait(studioService.searchStudioAnnullamentiByStudioId(location.state.idStudio)),
                wait(studioService.searchSostitutiByStudioId(location.state.idStudio)),
                wait(studioService.searchStudioOrariById(location.state.idStudio))
            ]).then(([studio, listOfStudioAnnullamenti, listOfSostituti, studioOrari]) => {
                if (studioOrari.data[0]) {
                    setOrariStudio(studioOrari.data[0]);
                } else {
                    if (orariStudio.idStudio === null) {
                        wait(studioService.studioOrariInsert({ idStudio: studio.data[0].idStudio }))
                            .then((resp) => {
                                console.log("Response Studio Orari insert:")
                                console.log(resp);
                                setOrariStudio(resp.data);
                            })
                    }
                }
                let respAnnullamenti = listOfStudioAnnullamenti.data;
                if (listOfStudioAnnullamenti.data.length > 0) {
                    let annullamentisToBeSet = [];
                    let key = 0;
                    respAnnullamenti.forEach(element => {
                        annullamentisToBeSet.push({
                            "annullamenti": element,
                            "populated": {
                                "dataDa": element.dataDa ? true : false,
                                "dataA": element.dataA ? true : false,
                                "oraDa": element.oraDa ? true : false,
                                "oraA": element.oraA ? true : false,
                            },
                            "isChanged": true,
                            "key": key++, "isValidOraDa": true, "isValidOraA": true
                        });
                    });
                    setAnnullamentoOrariStudioList(annullamentisToBeSet);

                    setKeyForAnnullamentiInput({ "key": (key) });
                } else {
                    setAnnullamentoOrariStudioList([{
                        "annullamenti": {
                            "idStudioAnnullamenti": null,
                            "idStudio": null,
                            "dataDa": null,
                            "dataA": null,
                            "oraDa": null,
                            "oraA": null
                        }, "populated": {
                            "dataDa": false,
                            "dataA": false,
                            "oraDa": false,
                            "oraA": false
                        },
                        "isChanged": false,
                        "key": 0, "isValidOraDa": true, "isValidOraA": true
                    }]);
                }


                let respSostituti = listOfSostituti.data;
                if (listOfSostituti.data.length > 0) {
                    let sostitutiToBeSet = [];
                    let key = 0;
                    respSostituti.forEach(element => {
                        sostitutiToBeSet.push({
                            "sostituto": { ...element, "dottoreSostituto": element.codFiscaleOfMedico },
                            "populated": {
                                "dottore": element.codFiscaleOfMedico ? true : false,
                                "dataDa": element.dataDa ? true : false,
                                "dataA": element.dataA ? true : false,
                            },
                            "isChanged": true,
                            "key": key++, "isValid": true,
                            "dateToInThePast": false,
                            "dateFromInThePast": false
                        });
                    });
                    setSostitutiList(sostitutiToBeSet);

                    setKeyForSostitutiInput({ "key": key });
                }
                else {
                    setSostitutiList([{
                        "sostituto": {
                            "dottoreSostituto": null,
                            "idMedicoSostituito": null,
                            "idStudio": null,
                            "dataDal": null,
                            "dataAl": null,
                            "idSostituzione": null,
                        }, "populated": {
                            "dottore": false,
                            "dataDa": false,
                            "dataA": false
                        },
                        "isChanged": false, "key": 0, "isValid": true, "dateToInThePast": false,
                        "dateFromInThePast": false
                    }]);
                }

                wait(studioService.searchComuneByCodIstatComune(studio.data[0].codIstatComune)).then((resp) => {
                    setIsComunePopulated(true);
                    setLabelComune(true);
                    setAnagrafica({ ...studio.data[0], "nomeComune": resp.data.nomeComune });
                    setInputComune({ "label": resp.data.nomeComune, "value": studio.data[0].codIstatComune });
                }).catch((err) => {
                    if (err.response && err.response.status === 512) {
                        toggleTermsAndConditions(true);
                    } else {
                        let errorMessage = err.response ? err.response.data : "Errore nel caricamento dei dati - Comune.";
                        window.alert(errorMessage);
                        navigate("/studi-medico");
                    }
                });
            }).catch((err) => {
                if (err.response && err.response.status === 512) {
                    toggleTermsAndConditions(true);
                } else {
                    let errorMessage = err.response ? err.response.data : "Errore nel caricamento dei dati.";
                    window.alert(errorMessage);
                    navigate("/studi-medico");
                }
            });
        } else {
            setIsPageChanged(true);
            setAnnullamentoOrariStudioList([{
                "annullamenti": {
                    "idStudioAnnullamenti": null,
                    "idStudio": null,
                    "dataDa": null,
                    "dataA": null,
                    "oraDa": null,
                    "oraA": null
                }, "populated": {
                    "dataDa": false,
                    "dataA": false,
                    "oraDa": false,
                    "oraA": false
                },
                "isChanged": false,
                "key": 0, "isValidOraDa": true, "isValidOraA": true
            }]);

            setSostitutiList([{
                "sostituto": {
                    "dottoreSostituto": null,
                    "idMedicoSostituito": null,
                    "idStudio": null,
                    "dataDal": null,
                    "dataAl": null,
                    "idSostituzione": null,
                }, "populated": {
                    "dottore": false,
                    "dataDa": false,
                    "dataA": false
                },
                "isChanged": false, "key": 0, "isValid": true, "dateToInThePast": false,
                "dateFromInThePast": false
            }]);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        durataAppuntamentoService.validate({}).then((resp) => {
            if (!resp.data) {
                navigate("/studi-medico");
                togglePopulateDurataAppuntamenti(true);
            }
        });
    }, []);

    useEffect(() => {
        setIsComunePopulated(true);
        setAnagrafica({
            "idStudio": anagrafica.idStudio,
            "nomeStudio": anagrafica.nomeStudio,
            "indirizzo": anagrafica.indirizzo,
            "cap": anagrafica.cap,
            "codIstatComune": inputComune.value,
            "telefonoLavoro": anagrafica.telefonoLavoro,
            "telefonoMobile": anagrafica.telefonoMobile,
            "telefonoFisso": anagrafica.telefonoFisso,
            "mail": anagrafica.mail,
            "nomeComune": inputComune.label
        });
    }, [inputComune]);

    useEffect(() => {
        if (!anagrafica.nomeStudio) {
            setRequiredNomeStudio(true);
        } else {
            setRequiredNomeStudio(false);
        }

        if (!anagrafica.codIstatComune) {
            setIsValidCodIstatComune(false);
        } else {
            setIsValidCodIstatComune(true);
        }
    }, [anagrafica])

    const [hasLoaded, setHasLoaded] = useState();

    useEffect(() => {
        for (let i = 0; i < annullamentoOrariStudioList.length; ++i) {
            if (annullamentoOrariStudioList[i].annullamenti.dataDa) {
                annullamentoOrariStudioList[i].populated.dataDa = true;
            } else {
                annullamentoOrariStudioList[i].populated.dataDa = false;
            }
            if (annullamentoOrariStudioList[i].annullamenti.dataA) {
                annullamentoOrariStudioList[i].populated.dataA = true;
            } else {
                annullamentoOrariStudioList[i].populated.dataA = false;
            }
            if (annullamentoOrariStudioList[i].annullamenti.oraDa) {
                annullamentoOrariStudioList[i].populated.oraDa = true;
            } else {
                annullamentoOrariStudioList[i].populated.oraDa = false;
            }
            if (annullamentoOrariStudioList[i].annullamenti.oraA) {
                annullamentoOrariStudioList[i].populated.oraA = true;
            } else {
                annullamentoOrariStudioList[i].populated.oraA = false;
            }
        }
        setHasLoaded(true);
    }, [annullamentoOrariStudioList])

    useEffect(() => {
        for (let i = 0; i < sostitutiList.length; ++i) {
            if (sostitutiList[i].sostituto.dottoreSostituto) {
                sostitutiList[i].populated.dottore = true;
            } else {
                sostitutiList[i].populated.dottore = false;
            }
            if (sostitutiList[i].sostituto.dataDal) {
                sostitutiList[i].populated.dataDa = true;
            } else {
                sostitutiList[i].populated.dataDa = false;
            }
            if (sostitutiList[i].sostituto.dataAl) {
                sostitutiList[i].populated.dataA = true;
            } else {
                sostitutiList[i].populated.dataA = false;
            }
        }
        setHasLoaded(true);
    }, [sostitutiList])

    const handleChangeComune = (selectedOption) => {
        setIsPageChanged(true);
        setInputComune(selectedOption ? selectedOption : { "label": null, "value": null });
    };
    const onInputChangeComune = (inputValue, action) => {
        switch (action.action) {
            case 'input-change':
                {
                    if (inputValue === '') {
                        setComuniLista([]);
                    } else {
                        let payload =
                        {
                            "comuneRicercato": inputValue
                        };
                        studiMediciService.listaComuni(payload).then((response) => {
                            let comuni = [];
                            comuni = response.data.map(
                                (comune) => {
                                    return {
                                        "label": comune.nomeComune,
                                        "value": comune.codIstatComune
                                    };
                                }
                            );
                            setComuniLista(comuni);
                        });
                    }
                }
        }
    };

    const handleChangeAnagrafica = (inputField, event) => {
        setIsPageChanged(true);
        setAnagrafica({
            "idStudio": (inputField === "idStudio") ? event.target.value : anagrafica.idStudio,
            "nomeStudio": (inputField === "nomeStudio") ? event.target.value : anagrafica.nomeStudio,
            "indirizzo": inputField === "indirizzo" ? event.target.value : anagrafica.indirizzo,
            "cap": inputField === "cap" ? event.target.value : anagrafica.cap,
            "codIstatComune": (inputField === "codIstatComune") ? event.target.value : anagrafica.codIstatComune,
            "nomeComune": (inputField === "nomeComune") ? event.target.value : anagrafica.nomeComune,
            "telefonoLavoro": inputField === "telefonoLavoro" ? event.target.value : anagrafica.telefonoLavoro,
            "telefonoMobile": inputField === "telefonoMobile" ? event.target.value : anagrafica.telefonoMobile,
            "telefonoFisso": inputField === "telefonoFisso" ? event.target.value : anagrafica.telefonoFisso,
            "mail": (inputField === "mail") ? event.target.value : anagrafica.mail
        });
        if (inputField === "mail") {
            emailValidation(event.target.value);
        }
        if (inputField === "cap") {
            capValidation(event.target.value);
        }
        if (inputField === "telefonoLavoro") {
            phoneValidation(event.target.value, setIsValidTelefono);
        }
        if (inputField === "telefonoMobile") {
            phoneValidation(event.target.value, setIsValidCellulare);
        }
    };



    const handleChangeOrariStudio = (inputField, value) => {
        setIsPageChanged(true);
        setOrariStudio({
            "idStudio": inputField === "idStudio" ? value : orariStudio.idStudio,
            "lunMattinaDa": (inputField === "lunMattinaDa") ? value : orariStudio.lunMattinaDa,
            "lunMattinaA": (inputField === "lunMattinaA") ? value : orariStudio.lunMattinaA,
            "lunPomeriggioDa": (inputField === "lunPomeriggioDa") ? value : orariStudio.lunPomeriggioDa,
            "lunPomeriggioA": (inputField === "lunPomeriggioA") ? value : orariStudio.lunPomeriggioA,

            "marMattinaDa": (inputField === "marMattinaDa") ? value : orariStudio.marMattinaDa,
            "marMattinaA": (inputField === "marMattinaA") ? value : orariStudio.marMattinaA,
            "marPomeriggioDa": (inputField === "marPomeriggioDa") ? value : orariStudio.marPomeriggioDa,
            "marPomeriggioA": (inputField === "marPomeriggioA") ? value : orariStudio.marPomeriggioA,

            "merMattinaDa": (inputField === "merMattinaDa") ? value : orariStudio.merMattinaDa,
            "merMattinaA": (inputField === "merMattinaA") ? value : orariStudio.merMattinaA,
            "merPomeriggioDa": (inputField === "merPomeriggioDa") ? value : orariStudio.merPomeriggioDa,
            "merPomeriggioA": (inputField === "merPomeriggioA") ? value : orariStudio.merPomeriggioA,

            "gioMattinaDa": (inputField === "gioMattinaDa") ? value : orariStudio.gioMattinaDa,
            "gioMattinaA": (inputField === "gioMattinaA") ? value : orariStudio.gioMattinaA,
            "gioPomeriggioDa": (inputField === "gioPomeriggioDa") ? value : orariStudio.gioPomeriggioDa,
            "gioPomeriggioA": (inputField === "gioPomeriggioA") ? value : orariStudio.gioPomeriggioA,

            "venMattinaDa": (inputField === "venMattinaDa") ? value : orariStudio.venMattinaDa,
            "venMattinaA": (inputField === "venMattinaA") ? value : orariStudio.venMattinaA,
            "venPomeriggioDa": (inputField === "venPomeriggioDa") ? value : orariStudio.venPomeriggioDa,
            "venPomeriggioA": (inputField === "venPomeriggioA") ? value : orariStudio.venPomeriggioA
        });
    };

    const handleChangeSostituti = (inputField, event, index) => {
        setIsPageChanged(true);
        setHasLoaded(false);
        sostitutiList[index].isChanged = true;
        if (inputField === "dottoreSostituto") {
            sostitutiList[index].sostituto.dottoreSostituto = event.target.value;
            codiceFiscaleValidation(event.target.value, index)
        }
        if (inputField === "dataDal") {
            let today = new Date();
            let newDate = new Date(event.target.value);
            if (newDate.getFullYear() < today.getFullYear() ||
                (newDate.getFullYear() === today.getFullYear() &&
                    (newDate.getMonth() < today.getMonth() ||
                        (newDate.getMonth() === today.getMonth() && newDate.getDate() < today.getDate())))) {
                sostitutiList[index].dateFromInThePast = true;
            } else {
                sostitutiList[index].dateFromInThePast = false;
            }
            sostitutiList[index].sostituto.dataDal = event.target.value;
        }
        if (inputField === "dataAl") {
            let today = new Date();
            let newDate = new Date(event.target.value);
            if (newDate.getFullYear() < today.getFullYear() ||
                (newDate.getFullYear() === today.getFullYear() &&
                    (newDate.getMonth() < today.getMonth() ||
                        (newDate.getMonth() === today.getMonth() && newDate.getDate() < today.getDate())))) {
                sostitutiList[index].dateToInThePast = true;
            } else {
                sostitutiList[index].dateToInThePast = false;
            }
            sostitutiList[index].sostituto.dataAl = event.target.value;
        }
        if (!sostitutiList[index].sostituto.dottoreSostituto && !sostitutiList[index].sostituto.dataDal && !sostitutiList[index].sostituto.dataAl) {
            sostitutiList[index].isChanged = false;
        }

        setSostitutiList([...sostitutiList]);

    };

    const handleChangeAnnullamentoOrariStudio = (inputField, value, index) => {
        setIsPageChanged(true);
        setHasLoaded(false);
        annullamentoOrariStudioList[index].isChanged = true;

        if (inputField === "dataDa") {
            annullamentoOrariStudioList[index].annullamenti.dataDa = value;
            requiredValidationWithIndexDataDa(value, index);
        }
        if (inputField === "dataA") {
            annullamentoOrariStudioList[index].annullamenti.dataA = value;
            requiredValidationWithIndexDataA(value, index);
        }
        if (inputField === "oraDa") {
            timeValidationForAnnullamentiOraDa(value, index);
            annullamentoOrariStudioList[index].annullamenti.oraDa = value;
        }
        if (inputField === "oraA") {
            timeValidationForAnnullamentiOraA(value, index);
            annullamentoOrariStudioList[index].annullamenti.oraA = value;
        }
        if (!annullamentoOrariStudioList[index].annullamenti.dataDa
            && !annullamentoOrariStudioList[index].annullamenti.dataA
            && !annullamentoOrariStudioList[index].annullamenti.oraDa
            && !annullamentoOrariStudioList[index].annullamenti.oraA) {
            annullamentoOrariStudioList[index].isChanged = false;
        }
        setAnnullamentoOrariStudioList([...annullamentoOrariStudioList]);
    };

    const addToSostitutiList = () => {
        if (areSostitutiListRequiredFieldsPopulated()) {
            setShowSostitutiRequirements(false);
            setKeyForSostitutiInput({ "key": (++keyForSostitutiInput.key) });

            setSostitutiList([...sostitutiList, {
                "sostituto": {
                    "dottoreSostituto": null,
                    "idMedicoSostituito": null,
                    "idStudio": null,
                    "dataDal": null,
                    "dataAl": null,
                }, "populated": {
                    "dottore": false,
                    "dataDa": false,
                    "dataA": false
                }, "isChanged": false, "key": keyForSostitutiInput.key, "isValid": true, "dateToInThePast": false,
                "dateFromInThePast": false
            }]);
        } else {
            setShowSostitutiRequirements(true);
        }
    }

    // Helper value to make the values for sostituti null when clicking and there is only one row
    const [clearDataForSostituti, setClearDataForSostituti] = useState(false);
    const removeFromSostitutiList = (index) => {
        if (sostitutiList.length > 1) {
            sostitutiList.splice(index, 1);
            setSostitutiList([...sostitutiList]);
        } else {
            setSostitutiList([{
                "sostituto": {
                    "dottoreSostituto": null,
                    "idMedicoSostituito": null,
                    "idStudio": null,
                    "dataDal": null,
                    "dataAl": null,
                    "idSostituzione": null,
                }, "populated": {
                    "dottore": false,
                    "dataDa": false,
                    "dataA": false
                },
                "isChanged": false, "key": 0, "isValid": true, "dateToInThePast": false,
                "dateFromInThePast": false
            }]);
            setShowSostitutiRequirements(false);
            setClearDataForSostituti(!clearDataForSostituti);
        }
    }

    const addToAnnullamentoOrariStudioList = () => {
        if (areAnnullamentiListRequiredFieldsPopulated()) {
            setShowAnnullamentiRequirements(false);
            setKeyForAnnullamentiInput({ "key": (++keyForAnnullamentiInput.key) });
            setAnnullamentoOrariStudioList([...annullamentoOrariStudioList, {
                "annullamenti": {
                    "idStudioAnnullamenti": null,
                    "idStudio": null,
                    "dataDa": null,
                    "dataA": null,
                    "oraDa": null,
                    "oraA": null
                }, "populated": {
                    "dataDa": false,
                    "dataA": false,
                    "oraDa": false,
                    "oraA": false
                }, "isChanged": false, "key": keyForAnnullamentiInput.key, "isValidOraDa": true, "isValidOraA": true
            }]);
        } else {
            setShowAnnullamentiRequirements(true);
        }
    }

    // Helper value to make the values for annullamenti null when clicking and there is only one row
    const [clearDataForAnnullamenti, setClearDataForAnnullamenti] = useState(false);
    const removeFromAnnullamentoOrariStudioList = (index) => {
        if (annullamentoOrariStudioList.length > 1) {
            annullamentoOrariStudioList.splice(index, 1);
            setAnnullamentoOrariStudioList([...annullamentoOrariStudioList]);
        } else {
            setAnnullamentoOrariStudioList([{
                "annullamenti": {
                    "idStudioAnnullamenti": null,
                    "idStudio": null,
                    "dataDa": null,
                    "dataA": null,
                    "oraDa": null,
                    "oraA": null
                }, "populated": {
                    "dataDa": false,
                    "dataA": false,
                    "oraDa": false,
                    "oraA": false
                },
                "isChanged": false,
                "key": 0, "isValidOraDa": true, "isValidOraA": true
            }]);
            setShowAnnullamentiRequirements(false);
            setClearDataForAnnullamenti(!clearDataForAnnullamenti);
        }
    }


    const timeValidationForAnnullamentiOraA = (value, index) => {
        annullamentoOrariStudioList[index].isValidOraA = timeFormatValidation(value);
    }


    const timeValidationForAnnullamentiOraDa = (value, index) => {
        annullamentoOrariStudioList[index].isValidOraDa = timeFormatValidation(value);
    }

    const requiredValidationWithIndexDataDa = (value, index) => {
        let isNotNull = !(value === null || value === "");
        annullamentoOrariStudioList[index].isValidDataDa = isNotNull;
    }


    const requiredValidationWithIndexDataA = (value, index) => {
        let isNotNull = !(value === null || value === "");
        annullamentoOrariStudioList[index].isValidDataA = isNotNull;
    }

    const codiceFiscaleValidation = (codiceFiscale, index) => {
        sostitutiList[index].isValid = !codiceFiscale || CODICE_FISCALE_REGEX.test(codiceFiscale);
    }

    const emailValidation = (email) => {
        setIsValidMail(!email || EMAIL_REGEX.test(email));
        return isValidMail;
    }

    const capValidation = (cap) => {
        setIsValidCap(!cap || CAP_REGEX.test(cap));
        return isValidCap;
    }

    const phoneValidation = (phone, setIsValid) => {
        setIsValid(!phone || PHONE_REGEX.test(phone));
    }

    const timeFormatValidation = (time) => {
        return !time || HHMM_TIME_FORMAT.test(time);
    }

    const oraDaOraATimeValidation = (oraDa, oraA) => {
        return (!oraDa && !oraA) ||
            ((timeFormatValidation(oraDa) && timeFormatValidation(oraA)) && timeComparisonValidation(oraDa, oraA));
    }

    const timeComparisonValidation = (oraDa, oraA) => {
        return (oraDa && oraA) && ((oraDa.slice(0, 2) < oraA.slice(0, 2)) || ((oraDa.slice(0, 2) === oraA.slice(0, 2)) && (oraDa.slice(3, 5) < oraA.slice(3, 5))));
    }

    const invalidStudioOrariHours = "Errore nell'inserimento degli orari studio: l'ora di inizio deve essere precedente all'ora di fine!";
    const isValidStudioOrari = () => {
        return (oraDaOraATimeValidation(orariStudio.lunMattinaDa, orariStudio.lunMattinaA) &&
            oraDaOraATimeValidation(orariStudio.lunPomeriggioDa, orariStudio.lunPomeriggioA) &&

            oraDaOraATimeValidation(orariStudio.marMattinaDa, orariStudio.marMattinaA) &&
            oraDaOraATimeValidation(orariStudio.marPomeriggioDa, orariStudio.marPomeriggioA) &&

            oraDaOraATimeValidation(orariStudio.merMattinaDa, orariStudio.merMattinaA) &&
            oraDaOraATimeValidation(orariStudio.merPomeriggioDa, orariStudio.merPomeriggioA) &&

            oraDaOraATimeValidation(orariStudio.gioMattinaDa, orariStudio.gioMattinaA) &&
            oraDaOraATimeValidation(orariStudio.gioPomeriggioDa, orariStudio.gioPomeriggioA) &&

            oraDaOraATimeValidation(orariStudio.venMattinaDa, orariStudio.venMattinaA) &&
            oraDaOraATimeValidation(orariStudio.venPomeriggioDa, orariStudio.venPomeriggioA))

    }

    const invalidMattinaHours = "L'orario della mattina deve essere compreso tra le 07:00 e le 14:00";
    const invalidPomeriggioHours = "L'orario del pomeriggio deve essere compreso tra le 14:01 e le 22:00";

    const validMattinaHours = (time) => {
        if (time !== null && time !== '') {
            let hour = Number(time.slice(0, 2));
            let minutes = Number(time.slice(3, 5));
            return ((hour >= 7 && hour <= 13) || (hour === 14 && minutes === 0));
        }
        return true;
    }

    const validPomeriggioHours = (time) => {
        if (time !== null && time !== '') {
            let hour = Number(time.slice(0, 2));
            let minutes = Number(time.slice(3, 5));
            return ((hour >= 15 && hour <= 21) || (hour === 14 && minutes >= 1) || (hour === 22 && minutes === 0));
        }
        return true;
    }

    const isStudioOrariWithinRange = () => {
        return (validMattinaHours(orariStudio.lunMattinaDa) &&
            validMattinaHours(orariStudio.lunMattinaA) &&
            validPomeriggioHours(orariStudio.lunPomeriggioDa) &&
            validPomeriggioHours(orariStudio.lunPomeriggioA) &&

            validMattinaHours(orariStudio.marMattinaDa) &&
            validMattinaHours(orariStudio.marMattinaA) &&
            validPomeriggioHours(orariStudio.marPomeriggioDa) &&
            validPomeriggioHours(orariStudio.marPomeriggioA) &&

            validMattinaHours(orariStudio.merMattinaDa) &&
            validMattinaHours(orariStudio.merMattinaA) &&
            validPomeriggioHours(orariStudio.merPomeriggioDa) &&
            validPomeriggioHours(orariStudio.merPomeriggioA) &&

            validMattinaHours(orariStudio.gioMattinaDa) &&
            validMattinaHours(orariStudio.gioMattinaA) &&
            validPomeriggioHours(orariStudio.gioPomeriggioDa) &&
            validPomeriggioHours(orariStudio.gioPomeriggioA) &&

            validMattinaHours(orariStudio.venMattinaDa) &&
            validMattinaHours(orariStudio.venMattinaA) &&
            validPomeriggioHours(orariStudio.venPomeriggioDa) &&
            validPomeriggioHours(orariStudio.venPomeriggioA))
    }

    const requiredFielldLabel = 'Il campo è obbligatiorio';

    const isSostitutiListValid = () => {
        for (let i = 0; i < sostitutiList.length; ++i) {
            if (!sostitutiList[i].isValid) {
                return false;
            }
        }
        return true;
    }

    const isAnnullamentiListValid = () => {
        for (let i = 0; i < annullamentoOrariStudioList.length; ++i) {
            if (!annullamentoOrariStudioList[i].isValidOraDa || !annullamentoOrariStudioList[i].isValidOraA) {
                return false;
            }
        }
        return true;

    }
    const areSostitutiListRequiredFieldsPopulatedForSalva = () => {
        if (sostitutiList.length === 1) {
            if ((sostitutiList[0].populated.dottore && sostitutiList[0].populated.dataDa && sostitutiList[0].populated.dataA) ||
                (!sostitutiList[0].populated.dottore && !sostitutiList[0].populated.dataDa && !sostitutiList[0].populated.dataA)) {
                return true;
            } else {
                return false;
            }
        }
        return areSostitutiListRequiredFieldsPopulated();
    }

    const areSostitutiDatesInThePast = () => {
        for (let i = 0; i < sostitutiList.length; ++i) {
            if (sostitutiList[i].dateFromInThePast || sostitutiList[i].dateToInThePast) {
                return true;
            }
        }
        return false;
    }

    const areAnnullamentiListRequiredFieldsPopulatedForSalva = () => {
        if (annullamentoOrariStudioList.length === 1) {
            if ((!annullamentoOrariStudioList[0].populated.dataDa && !annullamentoOrariStudioList[0].populated.dataA && !annullamentoOrariStudioList[0].populated.oraDa
                && !annullamentoOrariStudioList[0].populated.oraA) || (annullamentoOrariStudioList[0].populated.dataDa && annullamentoOrariStudioList[0].populated.dataA
                    && ((annullamentoOrariStudioList[0].populated.oraDa && annullamentoOrariStudioList[0].populated.oraA) ||
                        (!annullamentoOrariStudioList[0].populated.oraDa && !annullamentoOrariStudioList[0].populated.oraA)))) {
                return true
            }
            else {
                return false;
            }
        }
        return areAnnullamentiListRequiredFieldsPopulated();

    }

    const areSostitutiListRequiredFieldsPopulated = () => {
        for (let i = 0; i < sostitutiList.length; ++i) {
            if (!sostitutiList[i].populated.dottore || !sostitutiList[i].populated.dataDa || !sostitutiList[i].populated.dataA) {
                return false;
            }
        }
        return true;
    }

    const areAnnullamentiListRequiredFieldsPopulated = () => {
        for (let i = 0; i < annullamentoOrariStudioList.length; ++i) {
            if (!annullamentoOrariStudioList[i].populated.dataDa || !annullamentoOrariStudioList[i].populated.dataA ||
                (!annullamentoOrariStudioList[i].populated.oraDa && annullamentoOrariStudioList[i].populated.oraA) ||
                (annullamentoOrariStudioList[i].populated.oraDa && !annullamentoOrariStudioList[i].populated.oraA)) {
                return false;
            }
        }
        return true;
    }

    const [errorFieldsForSostituto, setErrorFieldsForSostituto] = useState([]);
    const [errorFieldsForAnnulamenti, setErrorFieldsForAnnullamenti] = useState([]);
    const [errorMessagesForOrariStudio, setErrorMessagesForOrariStudio] = useState(null);
    const [errorMessagesForSostituto, setErrorMessagesForSostituto] = useState(null);
    const [errorMessagesForAnnullamenti, setErrorMessagesForAnnullamenti] = useState(null);
    const [errorMessagesForAnnullamentiOraDaOraA, setErrorMessagesForAnnullamentiOraDaOraA] = useState(null);

    const isDatDaDaBeforeDataA = (dataDa, dataA) => {
        const da = new Date(dataDa);
        const a = new Date(dataA);

        return da.getTime() <= a.getTime();
    }

    const isDataDaDaEqualToDataA = (dataDa, dataA) => {
        const da = new Date(dataDa);
        const a = new Date(dataA);

        return da.getTime() === a.getTime();
    }

    const sotstitutiListCheckForDataDaToBeBeforeDataA = () => {
        let errorFields = [];
        for (let i = 0; i < sostitutiList.length; ++i) {
            if (sostitutiList[i].populated.dataDa && sostitutiList[i].populated.dataA && !isDatDaDaBeforeDataA(sostitutiList[i].sostituto.dataDal, sostitutiList[i].sostituto.dataAl)) {
                errorFields.push({
                    "dottore": sostitutiList[i].sostituto.dottoreSostituto,
                    "dataDa": sostitutiList[i].sostituto.dataDal,
                    "dataA": sostitutiList[i].sostituto.dataAl
                })
            }
        }
        setErrorFieldsForSostituto([...errorFields]);
        if (errorFields.length > 0) {
            return false;
        }
        return true;
    }
    const setErrorMessageWithErrorsForSostituto = errorData => {
        let errorMessages = "";
        for (let i = 0; i < errorData.length; ++i) {
            const da = new Date(errorData[i].dataDa);
            const a = new Date(errorData[i].dataA);
            errorMessages += "Errore nell'inserimento di un medico sostituto (" + errorData[i].dottore + "): la data di inizio (" + da.getDate() + '/' + (da.getMonth() + 1) + '/' + da.getFullYear() + ") deve essere precedente alla data di fine (" + a.getDate() + '/' + (a.getMonth() + 1) + '/' + a.getFullYear() + ")!";
            if (i !== errorData.length - 1) {
                errorMessages += "\n";
            }
        }
        setErrorMessagesForSostituto(errorMessages);
    }

    useEffect(() => {
        setErrorMessageWithErrorsForSostituto(errorFieldsForSostituto);
    }, [errorFieldsForSostituto])

    const annullamentiListCheckForDataDaToBeBeforeDataA = () => {
        let errorFields = [];
        for (let i = 0; i < annullamentoOrariStudioList.length; ++i) {
            if (annullamentoOrariStudioList[i].populated.dataDa && annullamentoOrariStudioList[i].populated.dataA) {
                if (!isDatDaDaBeforeDataA(annullamentoOrariStudioList[i].annullamenti.dataDa, annullamentoOrariStudioList[i].annullamenti.dataA)) {
                    errorFields.push({
                        "equalDates": false,
                        "da": annullamentoOrariStudioList[i].annullamenti.dataDa,
                        "a": annullamentoOrariStudioList[i].annullamenti.dataA
                    })
                } else if (isDataDaDaEqualToDataA(annullamentoOrariStudioList[i].annullamenti.dataDa, annullamentoOrariStudioList[i].annullamenti.dataA)
                    && annullamentoOrariStudioList[i].populated.oraDa && annullamentoOrariStudioList[i].populated.oraA &&
                    !oraDaOraATimeValidation(annullamentoOrariStudioList[i].annullamenti.oraDa, annullamentoOrariStudioList[i].annullamenti.oraA)) {
                    errorFields.push({
                        "equalDates": true,
                        "da": annullamentoOrariStudioList[i].annullamenti.oraDa,
                        "a": annullamentoOrariStudioList[i].annullamenti.oraA
                    })
                }
            }
        }

        setErrorFieldsForAnnullamenti([...errorFields]);
        if (errorFields.length > 0) {
            return false;
        }
        return true;
    }

    const invalidAnnullamentoOrariStudioHours = "Errore nell'inserimento degli annullamento orari studio: l'ora di inizio deve essere precedente all'ora di fine!";
    const isValidAnnullamentoStudioOrari = () => {
        for (let i = 0; i < annullamentoOrariStudioList.length; i++) {
            if ((annullamentoOrariStudioList[i].populated.oraDa && annullamentoOrariStudioList[i].populated.oraA) &&
                (!oraDaOraATimeValidation(annullamentoOrariStudioList[i].annullamenti.oraDa, annullamentoOrariStudioList[i].annullamenti.oraA))) {
                return false;
            }
        }

        return true;
    }


    const setErrorMessageWithErrorsForAnnullamenti = errorData => {
        let errorMessages = "";
        for (let i = 0; i < errorData.length; ++i) {
            if (!errorData[i].equalDates) {
                const da = new Date(errorData[i].da);
                const a = new Date(errorData[i].a);
                errorMessages += "Errore nell'annullamento: la data di inizio (" + da.getDate() + '/' + (da.getMonth() + 1) + '/' + da.getFullYear() + ") deve essere precedente alla data di fine (" + a.getDate() + '/' + (a.getMonth() + 1) + '/' + a.getFullYear() + ")!";
            }
            else {
                errorMessages += "Errore nell'annullamento: l'ora di inizio (" + errorData[i].da + ") deve essere precedente all'ora di fine (" + errorData[i].a + ")!";
            }
            if (i !== errorData.length - 1) {
                errorMessages += "\n";
            }
        }
        setErrorMessagesForAnnullamenti(errorMessages);
    }


    useEffect(() => {
        setErrorMessageWithErrorsForAnnullamenti(errorFieldsForAnnulamenti);
    }, [errorFieldsForAnnulamenti])

    const salvaClick = () => {
        setErrorMessage(null);
        setErrorMessagesForOrariStudio(null);
        if (isSostitutiListValid() && isAnnullamentiListValid() && isValidMail
            && isValidCap && isValidTelefono && isValidCellulare) {
            let isValidInput = true;
            if (requiredNomeStudio || !isValidCodIstatComune || !areSostitutiListRequiredFieldsPopulatedForSalva() || !areAnnullamentiListRequiredFieldsPopulatedForSalva()) {
                setIsSalvaClicked(true);
                isValidInput = false;
            }
            if (!isValidStudioOrari() && isStudioOrariWithinRange()) {
                setIsSalvaClicked(true);
                setErrorMessagesForOrariStudio(invalidStudioOrariHours);
                isValidInput = false;
            }
            if (!isStudioOrariWithinRange()) {
                setIsSalvaClicked(true);
                isValidInput = false;
            }
            if (!isValidAnnullamentoStudioOrari()) {
                setIsSalvaClicked(true);
                setErrorMessagesForAnnullamentiOraDaOraA(invalidAnnullamentoOrariStudioHours);
                isValidInput = false;
            }
            if (areSostitutiDatesInThePast()) {
                isValidInput = false;
            }
            if (!sotstitutiListCheckForDataDaToBeBeforeDataA()) {
                isValidInput = false;
            }
            if (!annullamentiListCheckForDataDaToBeBeforeDataA()) {
                isValidInput = false;
            }
            if (isValidInput) {
                let payloadForSostituti;
                if (sostitutiList.length === 1 && !sostitutiList[0].isChanged) {
                    payloadForSostituti = [];
                } else {
                    payloadForSostituti = sostitutiList.map((elem) => { return { ...elem.sostituto, "codFiscale": elem.sostituto.dottoreSostituto }; });
                }

                let payloadForAnnullamenti;

                if (annullamentoOrariStudioList.length === 1 && !annullamentoOrariStudioList[0].isChanged) {
                    payloadForAnnullamenti = [];
                } else {
                    payloadForAnnullamenti = annullamentoOrariStudioList
                        .map((elem) => {
                            return {
                                ...elem.annullamenti,
                                "oraA": elem.annullamenti.oraA,
                                "oraDa": elem.annullamenti.oraDa
                            };
                        });
                }

                const payloadForStudioOrari = {
                    "idStudio": orariStudio.idStudio ? orariStudio.idStudio : 0,
                    "lunMattinaDa": orariStudio.lunMattinaDa,
                    "lunMattinaA": orariStudio.lunMattinaA,
                    "lunPomeriggioDa": orariStudio.lunPomeriggioDa,
                    "lunPomeriggioA": orariStudio.lunPomeriggioA,
                    "marMattinaDa": orariStudio.marMattinaDa,
                    "marMattinaA": orariStudio.marMattinaA,
                    "marPomeriggioDa": orariStudio.marPomeriggioDa,
                    "marPomeriggioA": orariStudio.marPomeriggioA,
                    "merMattinaDa": orariStudio.merMattinaDa,
                    "merMattinaA": orariStudio.merMattinaA,
                    "merPomeriggioDa": orariStudio.merPomeriggioDa,
                    "merPomeriggioA": orariStudio.merPomeriggioA,
                    "gioMattinaDa": orariStudio.gioMattinaDa,
                    "gioMattinaA": orariStudio.gioMattinaA,
                    "gioPomeriggioDa": orariStudio.gioPomeriggioDa,
                    "gioPomeriggioA": orariStudio.gioPomeriggioA,
                    "venMattinaDa": orariStudio.venMattinaDa,
                    "venMattinaA": orariStudio.venMattinaA,
                    "venPomeriggioDa": orariStudio.venPomeriggioDa,
                    "venPomeriggioA": orariStudio.venPomeriggioA
                }

                if (location.state) {
                    const payloadForUpdate = {
                        "requestStudioUpdate": {
                            ...anagrafica
                        },
                        "requestStudioOrariUpdate": {
                            ...payloadForStudioOrari
                        },
                        "collectionOfRequestSostituzione": [
                            ...payloadForSostituti
                        ],
                        "collectionOfRequestStudioAnnullamenti": [
                            ...payloadForAnnullamenti
                        ]
                    }
                    wait(studioService.studiMedicoUpdate(payloadForUpdate)).then((resp) => {
                        navigate("/studi-medico");
                    }).catch((err) => {
                        if (err.response && err.response.status === 512) {
                            toggleTermsAndConditions(true);
                        } else {
                            setErrorMessage(err.response ? err.response.data : "Error updating the studio.");
                        }
                    });
                } else {
                    const payloadForInsert = {
                        "requestStudioInsert": {
                            ...anagrafica
                        },
                        "requestStudioOrariInsert": {
                            ...payloadForStudioOrari
                        },
                        "collectionOfRequestSostituzione": [
                            ...payloadForSostituti
                        ],
                        "collectionOfRequestStudioAnnullamenti": [
                            ...payloadForAnnullamenti
                        ]
                    }
                    wait(studioService.studiMedicoInsert(payloadForInsert)).then((resp) => {
                        navigate("/studi-medico");
                    }).catch((err) => {
                        if (err.response && err.response.status === 512) {
                            toggleTermsAndConditions(true);
                        } else {
                            setErrorMessage(err.response ? err.response.data : "Error updating the studio.");
                        }
                    });
                }

            }

        } else {
            // do not send request // tell the user there are validation errors
        }
    }

    const [isOpenSalva, toggleModalSalva] = useState(false);
    const [isOpenIndietro, toggleModalIndietro] = useState(false);


    const confirmSalvaClick = () => {
        salvaClick();
        toggleModalSalva(!isOpenSalva);
    }
    return (<Container fluid>
        <CustomBreadCrumb
            key={breadcrumbTitle}
            first={{
                "name": "Elenco Studi Medici",
                "onClick": () => {
                    if (isPageChanged) {
                        toggleModalIndietro(!isOpenIndietro);
                    } else {
                        navigate("/studi-medico");
                    }
                },
                "active": false
            }}
            second={{
                "name": breadcrumbTitle,
                "onClick": () => { },
                "active": true
            }}
        />
        <Accordion >
            <AccordionHeader
                className="d-none d-lg-block accordionButton"
                active={collapseOpen1}
                onToggle={() => setCollapseOpen1(!collapseOpen1)}
            >
                Anagrafica
            </AccordionHeader>
            <AccordionBody active={collapseOpen1}>
                <Row style={{ justifyContent: "space-around", marginTop: "1em", marginBottom: "-2.5em" }}>
                    <Col xs={12} md={6} lg={4} >
                        <Input
                            type='text'
                            label='Denomazione*'
                            infoText={isSalvaClicked && requiredNomeStudio && requiredFielldLabel}
                            invalid={(isSalvaClicked && requiredNomeStudio)}
                            value={anagrafica.nomeStudio}
                            onChange={(event) => { handleChangeAnagrafica("nomeStudio", event); }}
                            className='clickableLabel' />
                    </Col>
                    <Col xs={12} md={6} lg={4} >
                        <Input type='text' className='clickableLabel' value={anagrafica.indirizzo} label='Indirizzo' onChange={(event) => handleChangeAnagrafica("indirizzo", event)} />
                    </Col>
                    <Col xs={12} md={4} lg={1} >
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='CAP'
                            infoText={isValidCap ? false : "CAP non valido"}
                            invalid={!isValidCap}
                            value={anagrafica.cap}
                            onChange={(event) => handleChangeAnagrafica("cap", event)} />
                    </Col>
                    <Col xs={12} md={8} lg={3} >
                        <Col>
                            <div class="form-group">
                                <label htmlFor='selectExampleClassic'
                                    class={(labelComune) ? "active" : null}
                                    onClick={() => {
                                        setLabelComune(true);
                                        document.getElementById("input select comuni").focus();
                                    }}
                                >Comune*</label>
                                <Select
                                    key={anagrafica.nomeComune}
                                    id={"inputComune"}
                                    inputId={"input select comuni"}
                                    onChange={handleChangeComune}
                                    defaultValue={isComunePopulated && { label: isComunePopulated && anagrafica.nomeComune, value: isComunePopulated && anagrafica.codIstatComune }}
                                    placeholder=""
                                    isClearable={true}
                                    onBlur={() => {
                                        if ((inputComune.label == null || inputComune.label === "") && (inputComune.value == null || inputComune.value === "")) {
                                            setLabelComune(false)
                                        }
                                    }}
                                    onFocus={() => { setLabelComune(true) }}
                                    onInputChange={(inputValue, action) => { onInputChangeComune(inputValue, action) }}
                                    options={comuniLista}
                                    invalid={!isValidCodIstatComune}
                                    noOptionsMessage={({ inputValue }) => !inputValue ? null : "Nessun risultato"}
                                />
                            </div>
                            {(isSalvaClicked && !isValidCodIstatComune) && <div style={{ marginTop: "-3em" }}>
                                <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>
                            </div>}
                            {/* TODO Add validation label fo comune */}
                        </Col>
                    </Col>
                    <Col xs={12} md={4}  >
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Telefono'
                            infoText={isValidTelefono ? false : "Telefono non valido"}
                            invalid={!isValidTelefono}
                            value={anagrafica.telefonoLavoro}
                            onChange={(event) => handleChangeAnagrafica("telefonoLavoro", event)} />
                    </Col>
                    <Col xs={12} md={4}  >
                        <Input
                            type='text'
                            className='clickableLabel'
                            label='Cellulare'
                            infoText={isValidCellulare ? false : "Cellulare non valido"}
                            invalid={!isValidCellulare}
                            value={anagrafica.telefonoMobile}
                            onChange={(event) => handleChangeAnagrafica("telefonoMobile", event)} />
                    </Col>
                    <Col xs={12} md={4} >
                        <Input
                            type='email'
                            label='Email'
                            infoText={isValidMail ? false : "Email non valido"}
                            invalid={!isValidMail}
                            value={anagrafica.mail}
                            onChange={(event) => handleChangeAnagrafica("mail", event)}
                            className='clickableLabel' />
                    </Col>
                </Row>
            </AccordionBody>

            <AccordionHeader
                className='accordionButton'
                active={collapseOpen2}
                onToggle={() => setCollapseOpen2(!collapseOpen2)}
            >
                Orari Studio
            </AccordionHeader>
            <AccordionBody active={collapseOpen2}>
                <Row className='rowStyling' >
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Lunedi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.lunMattinaDa}
                                            placeholder='dalle'
                                            value={orariStudio.lunMattinaDa}
                                            onChange={(value) => handleChangeOrariStudio("lunMattinaDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.lunMattinaA !== null && orariStudio.lunMattinaA !== '')
                                            && (orariStudio.lunMattinaDa === null || orariStudio.lunMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.lunMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.lunMattinaA}
                                            placeholder='alle'
                                            value={orariStudio.lunMattinaA}
                                            onChange={(value) => handleChangeOrariStudio("lunMattinaA", value)} />
                                        {(isSalvaClicked && ((orariStudio.lunMattinaDa !== null && orariStudio.lunMattinaDa !== '')
                                            && (orariStudio.lunMattinaA === null || orariStudio.lunMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.lunMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.lunPomeriggioDa}
                                            placeholder='dalle'
                                            value={orariStudio.lunPomeriggioDa}
                                            onChange={(value) => handleChangeOrariStudio("lunPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.lunPomeriggioA !== null && orariStudio.lunPomeriggioA !== '')
                                            && (orariStudio.lunPomeriggioDa === null || orariStudio.lunPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.lunPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.lunPomeriggioA}
                                            placeholder='alle'
                                            value={orariStudio.lunPomeriggioA}
                                            onChange={(value) => handleChangeOrariStudio("lunPomeriggioA", value)} />
                                        {(isSalvaClicked && ((orariStudio.lunPomeriggioDa !== null && orariStudio.lunPomeriggioDa !== '')
                                            && (orariStudio.lunPomeriggioA === null || orariStudio.lunPomeriggioA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.lunPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Martedi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.marMattinaDa}
                                            placeholder='dalle'
                                            value={orariStudio.marMattinaDa}
                                            onChange={(value) => handleChangeOrariStudio("marMattinaDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.marMattinaA !== null && orariStudio.marMattinaA !== '')
                                            && (orariStudio.marMattinaDa === null || orariStudio.marMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.marMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.marMattinaA}
                                            placeholder='alle'
                                            value={orariStudio.marMattinaA}
                                            onChange={(value) => handleChangeOrariStudio("marMattinaA", value)} />
                                        {(isSalvaClicked && ((orariStudio.marMattinaDa !== null && orariStudio.marMattinaDa !== '')
                                            && (orariStudio.marMattinaA === null || orariStudio.marMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.marMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.marPomeriggioDa}
                                            placeholder='dalle'
                                            value={orariStudio.marPomeriggioDa}
                                            onChange={(value) => handleChangeOrariStudio("marPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.marPomeriggioA !== null && orariStudio.marPomeriggioA !== '')
                                            && (orariStudio.marPomeriggioDa === null || orariStudio.marPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.marPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.marPomeriggioA}
                                            placeholder='alle'
                                            value={orariStudio.marPomeriggioA}
                                            onChange={(value) => handleChangeOrariStudio("marPomeriggioA", value)} />
                                        {(isSalvaClicked && ((orariStudio.marPomeriggioDa !== null && orariStudio.marPomeriggioDa !== '')
                                            && (orariStudio.marPomeriggioA === null || orariStudio.marPomeriggioA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.marPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Mercoledi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.merMattinaDa}
                                            placeholder='dalle'
                                            value={orariStudio.merMattinaDa}
                                            onChange={(value) => handleChangeOrariStudio("merMattinaDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.merMattinaA !== null && orariStudio.merMattinaA !== '')
                                            && (orariStudio.merMattinaDa === null || orariStudio.merMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.merMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.merMattinaA}
                                            placeholder='alle'
                                            value={orariStudio.merMattinaA}
                                            onChange={(value) => handleChangeOrariStudio("merMattinaA", value)} />
                                        {(isSalvaClicked && ((orariStudio.merMattinaDa !== null && orariStudio.merMattinaDa !== '')
                                            && (orariStudio.merMattinaA === null || orariStudio.merMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.merMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.merPomeriggioDa}
                                            placeholder='dalle'
                                            value={orariStudio.merPomeriggioDa}
                                            onChange={(value) => handleChangeOrariStudio("merPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.merPomeriggioA !== null && orariStudio.merPomeriggioA !== '')
                                            && (orariStudio.merPomeriggioDa === null || orariStudio.merPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.merPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.merPomeriggioA}
                                            placeholder='alle'
                                            value={orariStudio.merPomeriggioA}
                                            onChange={(value) => handleChangeOrariStudio("merPomeriggioA", value)} />
                                        {(isSalvaClicked && ((orariStudio.merPomeriggioDa !== null && orariStudio.merPomeriggioDa !== '')
                                            && (orariStudio.merPomeriggioA === null || orariStudio.merPomeriggioA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.merPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Giovedi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.gioMattinaDa}
                                            placeholder='dalle'
                                            value={orariStudio.gioMattinaDa}
                                            onChange={(value) => handleChangeOrariStudio("gioMattinaDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.gioMattinaA !== null && orariStudio.gioMattinaA !== '')
                                            && (orariStudio.gioMattinaDa === null || orariStudio.gioMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.gioMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.gioMattinaA}
                                            placeholder='alle'
                                            value={orariStudio.gioMattinaA}
                                            onChange={(value) => handleChangeOrariStudio("gioMattinaA", value)} />
                                        {(isSalvaClicked && ((orariStudio.gioMattinaDa !== null && orariStudio.gioMattinaDa !== '')
                                            && (orariStudio.gioMattinaA === null || orariStudio.gioMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.gioMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.gioPomeriggioDa}
                                            placeholder='dalle'
                                            value={orariStudio.gioPomeriggioDa}
                                            onChange={(value) => handleChangeOrariStudio("gioPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.gioPomeriggioA !== null && orariStudio.gioPomeriggioA !== '')
                                            && (orariStudio.gioPomeriggioDa === null || orariStudio.gioPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.gioPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.gioPomeriggioA}
                                            placeholder='alle'
                                            value={orariStudio.gioPomeriggioA}
                                            onChange={(value) => handleChangeOrariStudio("gioPomeriggioA", value)} />
                                        {(isSalvaClicked && ((orariStudio.gioPomeriggioDa !== null && orariStudio.gioPomeriggioDa !== '')
                                            && (orariStudio.gioPomeriggioA === null || orariStudio.gioPomeriggioA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.gioPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Venerdi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.venMattinaDa}
                                            placeholder='dalle'
                                            value={orariStudio.venMattinaDa}
                                            onChange={(value) => handleChangeOrariStudio("venMattinaDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.venMattinaDa !== null && orariStudio.venMattinaDa !== '')
                                            && (orariStudio.venMattinaDa === null || orariStudio.venMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.venMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.venMattinaA}
                                            placeholder='alle'
                                            value={orariStudio.venMattinaA}
                                            onChange={(value) => handleChangeOrariStudio("venMattinaA", value)} />
                                        {(isSalvaClicked && ((orariStudio.venMattinaA !== null && orariStudio.venMattinaA !== '')
                                            && (orariStudio.venMattinaA === null || orariStudio.venMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(orariStudio.venMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.venPomeriggioDa}
                                            placeholder='dalle'
                                            value={orariStudio.venPomeriggioDa}
                                            onChange={(value) => handleChangeOrariStudio("venPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((orariStudio.venPomeriggioA !== null && orariStudio.venPomeriggioA !== '')
                                            && (orariStudio.venPomeriggioDa === null || orariStudio.venPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.venPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={orariStudio.venPomeriggioA}
                                            placeholder='alle'
                                            value={orariStudio.venPomeriggioA}
                                            onChange={(value) => handleChangeOrariStudio("venPomeriggioA", value)} />
                                        {(isSalvaClicked && ((orariStudio.venPomeriggioA !== null && orariStudio.venPomeriggioA !== '')
                                            && (orariStudio.lunMattinaDa === null || orariStudio.lunMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(orariStudio.venPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                </Row>
            </AccordionBody>

            <AccordionHeader
                className='accordionButton'
                active={collapseOpen3}
                onToggle={() => setCollapseOpen3(!collapseOpen3)}
            >
                Sostituti
            </AccordionHeader>
            <AccordionBody active={collapseOpen3}>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>

                    <Row>
                        <Col>
                            {sostitutiList.map((data, index) => {
                                return (
                                    <Row key={sostitutiList[index].key} style={{ columnGap: "2em", justifyContent: "space-around" }}>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}  >
                                            <Input
                                                key={clearDataForSostituti}
                                                name='dottoreSostituto'
                                                type='text'
                                                label='Dottore Sostituto*'
                                                infoText={(((isSalvaClicked && hasLoaded
                                                    && (sostitutiList.length > 1 || data.isChanged)) || showSostitutiRequirements)
                                                    && !data.populated.dottore && requiredFielldLabel) || (!data.isValid && "Codice fiscale non valido")}
                                                invalid={(((isSalvaClicked && hasLoaded && (sostitutiList.length > 1 || data.isChanged)) || showSostitutiRequirements) && !data.populated.dottore)
                                                    || !data.isValid}
                                                value={data.sostituto.dottoreSostituto}
                                                style={{ marginTop: "2em" }}
                                                onChange={(event) => handleChangeSostituti("dottoreSostituto", event, index)}
                                                className='clickableLabel' />
                                        </Col>

                                        <Col xs={12} sm={8} md={4} lg={4} xl={4}>
                                            <h6 className='text-secondary'>Periodo</h6>
                                            <Row>
                                                <Col xs={6} >
                                                    <CustomDateField
                                                        key={clearDataForSostituti}
                                                        placeholder="dal"
                                                        value={data.sostituto.dataDal}
                                                        onChange={(event) => handleChangeSostituti("dataDal", event, index)} />
                                                    {((data.dateFromInThePast &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>Attenzione: non è possibile indicare una data precedente a quella attuale</label>)
                                                        ||
                                                        ((((isSalvaClicked && hasLoaded && (sostitutiList.length > 1 || data.isChanged)) || showSostitutiRequirements)
                                                            && !data.populated.dataDa) &&
                                                            <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>))}
                                                </Col>
                                                <Col xs={6} >
                                                    <CustomDateField
                                                        key={clearDataForSostituti}
                                                        placeholder="al"
                                                        value={data.sostituto.dataAl}
                                                        onChange={(event) => handleChangeSostituti("dataAl", event, index)} />
                                                    {((data.dateToInThePast &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>Attenzione: non è possibile indicare una data precedente a quella attuale</label>)
                                                        ||
                                                        ((((isSalvaClicked && hasLoaded && (sostitutiList.length > 1 || data.isChanged)) || showSostitutiRequirements)
                                                            && !data.populated.dataA) &&
                                                            <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>))}
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                            <Row style={{ marginTop: "2.5em" }}>
                                                <Col>
                                                    <Icon icon="it-minus" color="white" style={{ backgroundColor: "#115287", "marginLeft": "5%", borderRadius: "10px" }} onClick={() => removeFromSostitutiList(index)} id={'minus_button' + index}></Icon>
                                                    <UncontrolledTooltip
                                                        placement='top'
                                                        target={'minus_button' + index}
                                                    >
                                                        Elimina riga
                                                    </UncontrolledTooltip>
                                                    {index === sostitutiList.length - 1 &&
                                                        <span>
                                                            <Icon icon="it-plus" color="white" style={{ backgroundColor: "#115287", "marginLeft": "5%", borderRadius: "10px" }} onClick={() => addToSostitutiList()} id={'plus_button' + index}></Icon>
                                                            <UncontrolledTooltip
                                                                placement='top'
                                                                target={'plus_button' + index}
                                                            >
                                                                Aggiungi riga
                                                            </UncontrolledTooltip>
                                                        </span>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                            }
                        </Col>
                    </Row>
                </Col>

            </AccordionBody>

            <AccordionHeader
                className='accordionButton'
                active={collapseOpen4}
                onToggle={() => setCollapseOpen4(!collapseOpen4)}
            >
                Annullamento Orari Studio
            </AccordionHeader>
            <AccordionBody active={collapseOpen4}>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>

                    <Row>
                        <Col>
                            {annullamentoOrariStudioList.map((data, index) => {
                                return (
                                    <Row key={annullamentoOrariStudioList[index].key} style={{ columnGap: "2em", justifyContent: "space-around" }}>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Row>
                                                <h6 className='mt-2 text-secondary'>Periodo</h6>
                                            </Row>
                                            <Row>
                                                <Col xs={6} >
                                                    <CustomDateField
                                                        key={clearDataForAnnullamenti}
                                                        placeholder="dal"
                                                        value={data.annullamenti.dataDa}
                                                        onChange={(event) => handleChangeAnnullamentoOrariStudio("dataDa", event.target.value, index)}
                                                    />
                                                    {(((isSalvaClicked && hasLoaded && (annullamentoOrariStudioList.length > 1 || data.isChanged)) || showAnnullamentiRequirements)
                                                        && !data.populated.dataDa) &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                                </Col>
                                                <Col xs={6} >
                                                    <CustomDateField
                                                        key={clearDataForAnnullamenti}
                                                        placeholder="al"
                                                        value={data.annullamenti.dataA}
                                                        onChange={(event) => handleChangeAnnullamentoOrariStudio("dataA", event.target.value, index)}
                                                    />
                                                    {(((isSalvaClicked && hasLoaded && (annullamentoOrariStudioList.length > 1 || data.isChanged)) || showAnnullamentiRequirements)
                                                        && !data.populated.dataA) &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Row>
                                                <h6 className='mt-2 text-secondary'>Fascia Oraria</h6>
                                            </Row>
                                            <Row className='removeZIndex'>
                                                <Col>
                                                    <CustomTimeField
                                                        key={data.annullamenti.oraDa}
                                                        placeholder='dalle'
                                                        value={data.annullamenti.oraDa}
                                                        onChange={(value) => handleChangeAnnullamentoOrariStudio("oraDa", value, index)} />

                                                    {((((isSalvaClicked && hasLoaded && (annullamentoOrariStudioList.length > 1 || data.isChanged)) || showAnnullamentiRequirements)
                                                        && !data.populated.oraDa && data.populated.oraA) &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>)}
                                                </Col>
                                                <Col>
                                                    <CustomTimeField
                                                        key={data.annullamenti.oraA}
                                                        placeholder='alle'
                                                        value={data.annullamenti.oraA}
                                                        onChange={(value) => handleChangeAnnullamentoOrariStudio("oraA", value, index)} />

                                                    {((((isSalvaClicked && hasLoaded && (annullamentoOrariStudioList.length > 1 || data.isChanged)) || showAnnullamentiRequirements)
                                                        && !data.populated.oraA && data.populated.oraDa) &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>)
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                            <Row style={{ marginTop: "2.5em" }}>
                                                <Col>
                                                    <Icon id={'annullament_minus_button' + index} icon="it-minus" color="white" style={{ backgroundColor: "#115287", "marginLeft": "5%", borderRadius: "10px" }} onClick={() => removeFromAnnullamentoOrariStudioList(index)}></Icon>
                                                    <UncontrolledTooltip
                                                        placement='top'
                                                        target={'annullament_minus_button' + index}
                                                    >
                                                        Elimina riga
                                                    </UncontrolledTooltip>
                                                    {index === annullamentoOrariStudioList.length - 1 &&
                                                        <span>
                                                            <Icon id={'annullament_plus_button' + index} icon="it-plus" color="white" style={{ backgroundColor: "#115287", "marginLeft": "5%", borderRadius: "10px" }} onClick={() => addToAnnullamentoOrariStudioList()}></Icon>
                                                            <UncontrolledTooltip
                                                                placement='top'
                                                                target={'annullament_plus_button' + index}
                                                            >
                                                                Aggiungi riga
                                                            </UncontrolledTooltip>
                                                        </span>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                            }
                        </Col>
                    </Row>
                </Col>
            </AccordionBody>

        </Accordion>
        <Container fluid style={{ marginBottom: "3%" }}>
            {(errorMessage) && <Alert color='danger' className='display-linebreak'>{errorMessage}</Alert>}
            {(errorMessagesForOrariStudio) && <Alert color='danger' className='display-linebreak'>{errorMessagesForOrariStudio}</Alert>}
            {(errorMessagesForSostituto) && <Alert color='danger' className='display-linebreak'>{errorMessagesForSostituto}</Alert>}
            {(errorMessagesForAnnullamenti) && <Alert color='danger' className='display-linebreak'>{errorMessagesForAnnullamenti}</Alert>}
            {(errorMessagesForAnnullamentiOraDaOraA) && <Alert color='danger' className='display-linebreak'>{errorMessagesForAnnullamentiOraDaOraA}</Alert>}
            <Row className="alignElementsCenterAndEnd" style={{ display: "flex" }}>
                <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                    <Button className="buttonPrimary" onClick={() => {
                        if (isPageChanged) {
                            toggleModalIndietro(!isOpenIndietro);
                        } else {
                            navigate("/studi-medico");
                        }
                    }} id='indietro_btn'>INDIETRO</Button>
                    <Modal
                        isOpen={isOpenIndietro}
                        toggle={() => toggleModalIndietro(!isOpenIndietro)}
                    >
                        <ModalHeader toggle={() => toggleModalIndietro(!isOpenIndietro)} id='esempio12'>
                            Indietro
                        </ModalHeader>
                        <ModalBody>
                            <p>Tutte le modifiche andranno perse. Sei sicuro di voler continuare?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => toggleModalIndietro(!isOpenIndietro)}>
                                Annulla
                            </Button>
                            <Button className="buttonPrimary" onClick={() => navigate("/studi-medico")}>
                                Conferma
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Col>
                <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                    <Button className="buttonPrimary" onClick={() => toggleModalSalva(!isOpenSalva)} id='salva_btn'>SALVA</Button>
                    <Modal
                        isOpen={isOpenSalva}
                        toggle={() => toggleModalSalva(!isOpenSalva)}
                    >
                        <ModalHeader toggle={() => toggleModalSalva(!isOpenSalva)} id='esempio12'>
                            CONFERMA
                        </ModalHeader>
                        <ModalBody>
                            <p>Confermi il salvataggio delle modifiche?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => toggleModalSalva(!isOpenSalva)}>
                                Annulla
                            </Button>
                            <Button className="buttonPrimary" onClick={confirmSalvaClick}>
                                Conferma
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Col>
            </Row>
        </Container>
    </Container>



    )
}

export default ModifyStudiMediciPage;