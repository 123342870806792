import axios from "axios";

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchListaDurataAppuntamenti = (payload) => {
  //return axios.post("http://localhost:8080/studi-medico/search", payload);
  return axios.post(`${BASE_ENDPOINT}/durata-appuntamento/search-durata-appuntamento`, payload);
};

const searchListaTipoAppuntamento = () => {
  //return axios.post("http://localhost:8080/studi-medico/search", payload);
  return axios.post(`${BASE_ENDPOINT}/tipo-appuntamento/search`);
};

const insertDurataAppuntamenti = (payload) => {
  //return axios.post("http://localhost:8080/studi-medico/search", payload);
  return axios.post(`${BASE_ENDPOINT}/durata-appuntamento/insert`, payload);
};

const updateDurataAppuntamenti = (payload) => {
  //return axios.post("http://localhost:8080/studi-medico/search", payload);
  return axios.post(`${BASE_ENDPOINT}/durata-appuntamento/update`, payload);
};

const modifyDurateAppuntamenti = (payload) => {
  //return axios.post("http://localhost:8080/studi-medico/search", payload);
  return axios.post(`${BASE_ENDPOINT}/durata-appuntamento/modify-durate`, payload);
};

const searchListaTipoDurataAppuntamento = () => {
  return axios.post(`${BASE_ENDPOINT}/durata-appuntamento/search-durata-appuntamento`);
};

export default {
  searchListaDurataAppuntamenti,
  searchListaTipoAppuntamento,
  searchListaTipoDurataAppuntamento,
  updateDurataAppuntamenti,
  modifyDurateAppuntamenti,
};
