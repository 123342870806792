import React, { memo } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import GestioneStudiMediciPage from "./gestioneStudiMedici/GestioneStudiMediciPage";
import ModifyStudiMediciPage from "./modifyStudiMedici/ModifyStudiMediciPage";
import AppuntamentiPage from "./appuntamenti/AppuntamentiPage";
import VisiteDomicilioPage from "./visiteDomicilio/VisiteDomicilioPage";
import DurataAppuntamentiPage from "./durataAppuntamenti/DurataAppuntamentiPage";
import Page from "./scheleton/page";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

const Root = React.memo(function (props) {
  const { keycloak } = useKeycloak();

  axios.interceptors.request.use(
    (request) => {
      if (keycloak.token) {
        request.headers.common.Authorization = `Bearer ${keycloak.token}`;
      }
      return request;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  return (
    <Routes>
      <Route
        path="studi-medico"
        element={
          keycloak.authenticated && <GestioneStudiMediciPage togglePopulateDurataAppuntamenti={props.togglePopulateDurataAppuntamenti} toggleTermsAndConditions={props.toggleTermsAndConditions} />
        }
      />
      <Route
        path="modify-studi-medici"
        element={
          keycloak.authenticated && (
            <ModifyStudiMediciPage
              togglePopulateDurataAppuntamenti={props.togglePopulateDurataAppuntamenti}
              isPageChanged={props.isPageChanged}
              setIsPageChanged={props.setIsPageChanged}
              toggleTermsAndConditions={props.toggleTermsAndConditions}
            />
          )
        }
      />
      <Route path="appuntamenti" element={keycloak.authenticated && <AppuntamentiPage toggleTermsAndConditions={props.toggleTermsAndConditions} />} />
      <Route
        path="gestione-visite-domicilio"
        element={
          keycloak.authenticated && <VisiteDomicilioPage togglePopulateDurataAppuntamenti={props.togglePopulateDurataAppuntamenti} isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} toggleTermsAndConditions={props.toggleTermsAndConditions} />
        }
      />
      <Route
        path="gestione-durata-appuntamenti"
        element={
          keycloak.authenticated && <DurataAppuntamentiPage isPageChanged={props.isPageChanged} setIsPageChanged={props.setIsPageChanged} toggleTermsAndConditions={props.toggleTermsAndConditions} />
        }
      />

      <Route path="" element={<Navigate replace to="appuntamenti" />} />
    </Routes>
  );
});
const RootPage = ({ ...props }) => {
  return <Page body={<Root {...props} />} />;
};
export default memo(RootPage);
