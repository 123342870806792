import axios from "axios";

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchDomicilireOrariByMedicoCodFiscale = (payload) => {
  return axios.post(`${BASE_ENDPOINT}/domiciliare-orari/search`, payload);
  //return axios.post("http://localhost:8080/domiciliare-orari/search", payload);
};

const searchDomiclireAnnullamentiByMedicoCodFiscale = (payload) => {
  return axios.post(`${BASE_ENDPOINT}/domicliare-annullamenti/search`, payload);
  //return axios.post("http://localhost:8080/domiciliare-orari/search", payload);
};

const modifyVisiteDomicilio = (payload) => {
  return axios.post(`${BASE_ENDPOINT}/visite-domicile/modify`, payload);
  //return axios.post("http://localhost:8080/visite-domicile/modify", payload);
};

export default {
  searchDomicilireOrariByMedicoCodFiscale,
  searchDomiclireAnnullamentiByMedicoCodFiscale,
  modifyVisiteDomicilio,
};
