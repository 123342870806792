import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import React from "react";
import { useNavigate } from 'react-router-dom';

export const PopulateDurataAppuntamentiModal = ({
    populateDurataAppuntamentiModalIsOpen,
    togglePopulateDurataAppuntamenti
}) => {

    const navigate = useNavigate();
    return (<>
        {populateDurataAppuntamentiModalIsOpen && 
            <Modal
                key={populateDurataAppuntamentiModalIsOpen}    
                isOpen={populateDurataAppuntamentiModalIsOpen}
                toggle={() => togglePopulateDurataAppuntamenti(!populateDurataAppuntamentiModalIsOpen)}
            >
                <ModalHeader toggle={() => togglePopulateDurataAppuntamenti(!populateDurataAppuntamentiModalIsOpen)} id='esempio12'>
                    Impostazione Durate Appuntamenti
                </ModalHeader>
                <ModalBody>
                    <p>Per consentire la fruizione degli appuntamenti ai propri assistiti è necessario impostare la durata per le varie tipologie di appuntamento</p>
                </ModalBody>
                <ModalFooter>
                    <Button className="buttonPrimary" onClick={() => togglePopulateDurataAppuntamenti(!populateDurataAppuntamentiModalIsOpen)}>
                        Annulla
                    </Button>
                    <Button 
                        className="buttonPrimary" 
                        onClick={() => {
                            navigate("/gestione-durata-appuntamenti"); 
                            togglePopulateDurataAppuntamenti(false);
                        }}
                    >
                        Imposta
                    </Button>
                </ModalFooter>
            </Modal>}           
            </>)
}

