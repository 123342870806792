import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const validate = payload => {
    return axios.post(`${BASE_ENDPOINT}/durata-appuntamento/validate`, payload);
};

export default {
    validate
};