import { Accordion, AccordionHeader, AccordionBody, Col, Container, Button, Row, Icon, UncontrolledTooltip, Alert, ModalFooter, ModalBody, ModalHeader, Modal } from 'design-react-kit';
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { wait } from './../common/Loading';
import { HHMM_TIME_FORMAT } from "../constants/regexConstants";
import durataAppuntamentoService from '../service/durataAppuntamentoService';
import '../../App.css';
import { CustomDateField } from '../custom/CustomDateField';
import { CustomTimeField } from '../custom/CustomTimeField';
import domiciliareService from '../service/domiciliareService';
import { CustomBreadCrumb } from '../custom/CustomBreadCrumb';

function VisiteDomicilioPage({
    isPageChanged,
    setIsPageChanged,
    toggleTermsAndConditions,
    togglePopulateDurataAppuntamenti }) {
    const [errorMessage, setErrorMessage] = useState(null);

    const [messageFromRequest, setMessageFromRequest] = useState({
        'state': false,
        'text': '',
        'type': ''
    });

    const navigate = useNavigate();
    const location = useLocation();

    const [collapseOpen1, setCollapseOpen1] = useState(true);
    const [collapseOpen2, setCollapseOpen2] = useState(true);

    const [isSalvaClicked, setIsSalvaClicked] = useState(false);
    const [showAnnullamentiRequirements, setShowAnnullamentiRequirements] = useState(false);

    const [keyForAnnullamentiInput, setKeyForAnnullamentiInput] = useState({ "key": 0 });
    const [annullamentoOrariDomiciliariList, setAnnullamentoOrariDomiciliariList] = useState([]);

    const [domiciliareOrari, setDomiciliareOrari] = useState({
        "idDomiciliareOrari": null,
        "lunMattinaDa": null,
        "lunMattinaA": null,
        "lunPomeriggioDa": null,
        "lunPomeriggioA": null,
        "marMattinaDa": null,
        "marMattinaA": null,
        "marPomeriggioDa": null,
        "marPomeriggioA": null,
        "merMattinaDa": null,
        "merMattinaA": null,
        "merPomeriggioDa": null,
        "merPomeriggioA": null,
        "gioMattinaDa": null,
        "gioMattinaA": null,
        "gioPomeriggioDa": null,
        "gioPomeriggioA": null,
        "venMattinaDa": null,
        "venMattinaA": null,
        "venPomeriggioDa": null,
        "venPomeriggioA": null
    })

    const handleError = (err) => {
        if (err.response && err.response.status === 512) {
            toggleTermsAndConditions(true);
        }
    }

    useEffect(() => {
        durataAppuntamentoService.validate({}).then((resp) => {
            if (!resp.data) {
                togglePopulateDurataAppuntamenti(true);
            }
        });
    }, []);

    useEffect(() => {
        Promise.all([
            wait(domiciliareService.searchDomicilireOrariByMedicoCodFiscale({})),
            wait(domiciliareService.searchDomiclireAnnullamentiByMedicoCodFiscale({}))
        ]).then(([domiciliareOrari, listOfDomiciliareAnnullamenti]) => {
            if (domiciliareOrari.data.length > 0) {
                setDomiciliareOrari(domiciliareOrari.data[0]);
            }

            let respAnnullamenti = listOfDomiciliareAnnullamenti.data;
            if (listOfDomiciliareAnnullamenti.data.length > 0) {
                let annullamentisToBeSet = [];
                let key = 0;
                respAnnullamenti.forEach(element => {
                    annullamentisToBeSet.push({
                        "annullamenti": element,
                        "populated": {
                            "dataDa": element.dataDa ? true : false,
                            "dataA": element.dataA ? true : false,
                            "oraDa": element.oraDa ? true : false,
                            "oraA": element.oraA ? true : false,
                        },
                        "isChanged": true,
                        "key": key++, "isValidOraDa": true, "isValidOraA": true
                    });
                });
                setAnnullamentoOrariDomiciliariList(annullamentisToBeSet);

                setKeyForAnnullamentiInput({ "key": (key) });
            } else {
                setAnnullamentoOrariDomiciliariList([{
                    "annullamenti": {
                        "idDomiciliareAnnullamenti": null,
                        "idMedico": null,
                        "dataDa": null,
                        "dataA": null,
                        "oraDa": null,
                        "oraA": null
                    }, "populated": {
                        "dataDa": false,
                        "dataA": false,
                        "oraDa": false,
                        "oraA": false
                    },
                    "isChanged": false,
                    "key": 0, "isValidOraDa": true, "isValidOraA": true
                }]);
            }
        }).catch((err) => {
            if (err.response && err.response.status === 512) {
                toggleTermsAndConditions(true);
            } else {
                setMessageFromRequest({
                    'state': true,
                    'text': err.response ? err.response.data : "Errore nel caricamento dei dati.",
                    'type': 'danger'
                });
            }
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [hasLoaded, setHasLoaded] = useState();

    useEffect(() => {
        for (let i = 0; i < annullamentoOrariDomiciliariList.length; ++i) {
            if (annullamentoOrariDomiciliariList[i].annullamenti.dataDa) {
                annullamentoOrariDomiciliariList[i].populated.dataDa = true;
            } else {
                annullamentoOrariDomiciliariList[i].populated.dataDa = false;
            }
            if (annullamentoOrariDomiciliariList[i].annullamenti.dataA) {
                annullamentoOrariDomiciliariList[i].populated.dataA = true;
            } else {
                annullamentoOrariDomiciliariList[i].populated.dataA = false;
            }
            if (annullamentoOrariDomiciliariList[i].annullamenti.oraDa) {
                annullamentoOrariDomiciliariList[i].populated.oraDa = true;
            } else {
                annullamentoOrariDomiciliariList[i].populated.oraDa = false;
            }
            if (annullamentoOrariDomiciliariList[i].annullamenti.oraA) {
                annullamentoOrariDomiciliariList[i].populated.oraA = true;
            } else {
                annullamentoOrariDomiciliariList[i].populated.oraA = false;
            }
        }
        setHasLoaded(true);
    }, [annullamentoOrariDomiciliariList])

    const handleChangeDomiciliareOrari = (inputField, value) => {
        setIsPageChanged(true);
        setDomiciliareOrari({
            "idDomiciliareOrari": inputField === "idDomiciliareOrari" ? value : domiciliareOrari.idDomiciliareOrari,
            "lunMattinaDa": (inputField === "lunMattinaDa") ? value : domiciliareOrari.lunMattinaDa,
            "lunMattinaA": (inputField === "lunMattinaA") ? value : domiciliareOrari.lunMattinaA,
            "lunPomeriggioDa": (inputField === "lunPomeriggioDa") ? value : domiciliareOrari.lunPomeriggioDa,
            "lunPomeriggioA": (inputField === "lunPomeriggioA") ? value : domiciliareOrari.lunPomeriggioA,

            "marMattinaDa": (inputField === "marMattinaDa") ? value : domiciliareOrari.marMattinaDa,
            "marMattinaA": (inputField === "marMattinaA") ? value : domiciliareOrari.marMattinaA,
            "marPomeriggioDa": (inputField === "marPomeriggioDa") ? value : domiciliareOrari.marPomeriggioDa,
            "marPomeriggioA": (inputField === "marPomeriggioA") ? value : domiciliareOrari.marPomeriggioA,

            "merMattinaDa": (inputField === "merMattinaDa") ? value : domiciliareOrari.merMattinaDa,
            "merMattinaA": (inputField === "merMattinaA") ? value : domiciliareOrari.merMattinaA,
            "merPomeriggioDa": (inputField === "merPomeriggioDa") ? value : domiciliareOrari.merPomeriggioDa,
            "merPomeriggioA": (inputField === "merPomeriggioA") ? value : domiciliareOrari.merPomeriggioA,

            "gioMattinaDa": (inputField === "gioMattinaDa") ? value : domiciliareOrari.gioMattinaDa,
            "gioMattinaA": (inputField === "gioMattinaA") ? value : domiciliareOrari.gioMattinaA,
            "gioPomeriggioDa": (inputField === "gioPomeriggioDa") ? value : domiciliareOrari.gioPomeriggioDa,
            "gioPomeriggioA": (inputField === "gioPomeriggioA") ? value : domiciliareOrari.gioPomeriggioA,

            "venMattinaDa": (inputField === "venMattinaDa") ? value : domiciliareOrari.venMattinaDa,
            "venMattinaA": (inputField === "venMattinaA") ? value : domiciliareOrari.venMattinaA,
            "venPomeriggioDa": (inputField === "venPomeriggioDa") ? value : domiciliareOrari.venPomeriggioDa,
            "venPomeriggioA": (inputField === "venPomeriggioA") ? value : domiciliareOrari.venPomeriggioA
        });
    };

    const oraDaOraATimeValidation = (oraDa, oraA) => {
        return (!oraDa && !oraA) ||
            ((timeFormatValidation(oraDa) && timeFormatValidation(oraA)) && timeComparisonValidation(oraDa, oraA));
    }

    const timeComparisonValidation = (oraDa, oraA) => {
        return (oraDa && oraA) && ((oraDa.slice(0, 2) < oraA.slice(0, 2)) || ((oraDa.slice(0, 2) === oraA.slice(0, 2)) && (oraDa.slice(3, 5) < oraA.slice(3, 5))));
    }

    const invalidDomiciliareOrariMessage = "Orari Studio: L'ora 'a' deve essere successiva all'ora 'da'!";
    const isValidDomiciliareOrari = () => {
        return (oraDaOraATimeValidation(domiciliareOrari.lunMattinaDa, domiciliareOrari.lunMattinaA) &&
            oraDaOraATimeValidation(domiciliareOrari.lunPomeriggioDa, domiciliareOrari.lunPomeriggioA) &&

            oraDaOraATimeValidation(domiciliareOrari.marMattinaDa, domiciliareOrari.marMattinaA) &&
            oraDaOraATimeValidation(domiciliareOrari.marPomeriggioDa, domiciliareOrari.marPomeriggioA) &&

            oraDaOraATimeValidation(domiciliareOrari.merMattinaDa, domiciliareOrari.merMattinaA) &&
            oraDaOraATimeValidation(domiciliareOrari.merPomeriggioDa, domiciliareOrari.merPomeriggioA) &&

            oraDaOraATimeValidation(domiciliareOrari.gioMattinaDa, domiciliareOrari.gioMattinaA) &&
            oraDaOraATimeValidation(domiciliareOrari.gioPomeriggioDa, domiciliareOrari.gioPomeriggioA) &&

            oraDaOraATimeValidation(domiciliareOrari.venMattinaDa, domiciliareOrari.venMattinaA) &&
            oraDaOraATimeValidation(domiciliareOrari.venPomeriggioDa, domiciliareOrari.venPomeriggioA))
    }

    const invalidMattinaHours = "L'orario della mattina deve essere compreso tra le 07:00 e le 14:00";
    const invalidPomeriggioHours = "L'orario del pomeriggio deve essere compreso tra le 14:01 e le 22:00";

    const validMattinaHours = (time) => {
        if (time !== null && time !== '') {
            let hour = Number(time.slice(0, 2));
            let minutes = Number(time.slice(3, 5));
            return ((hour >= 7 && hour <= 13) || (hour === 14 && minutes === 0));
        }
        return true;
    }

    const validPomeriggioHours = (time) => {
        if (time !== null && time !== '') {
            let hour = Number(time.slice(0, 2));
            let minutes = Number(time.slice(3, 5));
            return ((hour >= 15 && hour <= 21) || (hour === 14 && minutes >= 1) || (hour === 22 && minutes === 0));
        }
        return true;
    }

    const isDomiciliareOrariWithinRange = () => {
        return (validMattinaHours(domiciliareOrari.lunMattinaDa) &&
            validMattinaHours(domiciliareOrari.lunMattinaA) &&
            validPomeriggioHours(domiciliareOrari.lunPomeriggioDa) &&
            validPomeriggioHours(domiciliareOrari.lunPomeriggioA) &&

            validMattinaHours(domiciliareOrari.marMattinaDa) &&
            validMattinaHours(domiciliareOrari.marMattinaA) &&
            validPomeriggioHours(domiciliareOrari.marPomeriggioDa) &&
            validPomeriggioHours(domiciliareOrari.marPomeriggioA) &&

            validMattinaHours(domiciliareOrari.merMattinaDa) &&
            validMattinaHours(domiciliareOrari.merMattinaA) &&
            validPomeriggioHours(domiciliareOrari.merPomeriggioDa) &&
            validPomeriggioHours(domiciliareOrari.merPomeriggioA) &&

            validMattinaHours(domiciliareOrari.gioMattinaDa) &&
            validMattinaHours(domiciliareOrari.gioMattinaA) &&
            validPomeriggioHours(domiciliareOrari.gioPomeriggioDa) &&
            validPomeriggioHours(domiciliareOrari.gioPomeriggioA) &&

            validMattinaHours(domiciliareOrari.venMattinaDa) &&
            validMattinaHours(domiciliareOrari.venMattinaA) &&
            validPomeriggioHours(domiciliareOrari.venPomeriggioDa) &&
            validPomeriggioHours(domiciliareOrari.venPomeriggioA))
    }

    const handleChangeAnnullamentoOrariDomicilio = (inputField, value, index) => {
        setIsPageChanged(true);
        setHasLoaded(false);
        annullamentoOrariDomiciliariList[index].isChanged = true;

        if (inputField === "dataDa") {
            annullamentoOrariDomiciliariList[index].annullamenti.dataDa = value;
            requiredValidationWithIndexDataDa(value, index);
        }
        if (inputField === "dataA") {
            annullamentoOrariDomiciliariList[index].annullamenti.dataA = value;
            requiredValidationWithIndexDataA(value, index);
        }
        if (inputField === "oraDa") {
            timeValidationForAnnullamentiOraDa(value, index);
            annullamentoOrariDomiciliariList[index].annullamenti.oraDa = value;
        }
        if (inputField === "oraA") {
            timeValidationForAnnullamentiOraA(value, index);
            annullamentoOrariDomiciliariList[index].annullamenti.oraA = value;
        }
        if (!annullamentoOrariDomiciliariList[index].annullamenti.dataDa
            && !annullamentoOrariDomiciliariList[index].annullamenti.dataA
            && !annullamentoOrariDomiciliariList[index].annullamenti.oraDa
            && !annullamentoOrariDomiciliariList[index].annullamenti.oraA) {
            annullamentoOrariDomiciliariList[index].isChanged = false;
        }
        setAnnullamentoOrariDomiciliariList([...annullamentoOrariDomiciliariList]);
    };

    const addToAnnullamentoOrariDomiciliariList = () => {
        if (areAnnullamentiListRequiredFieldsPopulated()) {
            setShowAnnullamentiRequirements(false);
            setKeyForAnnullamentiInput({ "key": (++keyForAnnullamentiInput.key) });
            setAnnullamentoOrariDomiciliariList([...annullamentoOrariDomiciliariList, {
                "annullamenti": {
                    "idDomiciliareAnnullamenti": null,
                    "idMedico": null,
                    "dataDa": null,
                    "dataA": null,
                    "oraDa": null,
                    "oraA": null
                }, "populated": {
                    "dataDa": false,
                    "dataA": false,
                    "oraDa": false,
                    "oraA": false
                }, "isChanged": false, "key": keyForAnnullamentiInput.key, "isValidOraDa": true, "isValidOraA": true
            }]);
        } else {
            setShowAnnullamentiRequirements(true);
        }
    }

    // Helper value to make the values for annullamenti null when clicking and there is only one row
    const [clearDataForAnnullamenti, setClearDataForAnnullamenti] = useState(false);
    const removeFromAnnullamentoOrariDomiciliariList = (index) => {
        if (annullamentoOrariDomiciliariList.length > 1) {
            annullamentoOrariDomiciliariList.splice(index, 1);
            setAnnullamentoOrariDomiciliariList([...annullamentoOrariDomiciliariList]);
        } else {
            setAnnullamentoOrariDomiciliariList([{
                "annullamenti": {
                    "idDomiciliareAnnullamenti": null,
                    "idMedico": null,
                    "dataDa": null,
                    "dataA": null,
                    "oraDa": null,
                    "oraA": null
                }, "populated": {
                    "dataDa": false,
                    "dataA": false,
                    "oraDa": false,
                    "oraA": false
                },
                "isChanged": false,
                "key": 0, "isValidOraDa": true, "isValidOraA": true
            }]);
            setShowAnnullamentiRequirements(false);
            setClearDataForAnnullamenti(!clearDataForAnnullamenti);
        }
    }

    const timeValidationForAnnullamentiOraA = (value, index) => {
        annullamentoOrariDomiciliariList[index].isValidOraA = timeFormatValidation(value);
    }

    const timeValidationForAnnullamentiOraDa = (value, index) => {
        annullamentoOrariDomiciliariList[index].isValidOraDa = timeFormatValidation(value);
    }

    const requiredValidationWithIndexDataDa = (value, index) => {
        let isNotNull = !(value === null || value === "");
        annullamentoOrariDomiciliariList[index].isValidDataDa = isNotNull;
    }

    const requiredValidationWithIndexDataA = (value, index) => {
        let isNotNull = !(value === null || value === "");
        annullamentoOrariDomiciliariList[index].isValidDataA = isNotNull;
    }

    const timeFormatValidation = (time) => {
        return !time || HHMM_TIME_FORMAT.test(time);
    }

    const requiredFielldLabel = 'Il campo è obbligatiorio';

    const isAnnullamentiListValid = () => {
        for (let i = 0; i < annullamentoOrariDomiciliariList.length; ++i) {
            if (!annullamentoOrariDomiciliariList[i].isValidOraDa || !annullamentoOrariDomiciliariList[i].isValidOraA) {
                return false;
            }
        }
        return true;
    }

    const areAnnullamentiListRequiredFieldsPopulatedForSalva = () => {
        if (annullamentoOrariDomiciliariList.length === 1) {
            if ((!annullamentoOrariDomiciliariList[0].populated.dataDa && !annullamentoOrariDomiciliariList[0].populated.dataA && !annullamentoOrariDomiciliariList[0].populated.oraDa
                && !annullamentoOrariDomiciliariList[0].populated.oraA) || (annullamentoOrariDomiciliariList[0].populated.dataDa && annullamentoOrariDomiciliariList[0].populated.dataA
                    && ((annullamentoOrariDomiciliariList[0].populated.oraDa && annullamentoOrariDomiciliariList[0].populated.oraA) ||
                        (!annullamentoOrariDomiciliariList[0].populated.oraDa && !annullamentoOrariDomiciliariList[0].populated.oraA)))) {
                return true
            }
            else {
                return false;
            }
        }
        return areAnnullamentiListRequiredFieldsPopulated();
    }

    const areAnnullamentiListRequiredFieldsPopulated = () => {
        for (let i = 0; i < annullamentoOrariDomiciliariList.length; ++i) {
            if (!annullamentoOrariDomiciliariList[i].populated.dataDa || !annullamentoOrariDomiciliariList[i].populated.dataA ||
                (!annullamentoOrariDomiciliariList[i].populated.oraDa && annullamentoOrariDomiciliariList[i].populated.oraA) ||
                (annullamentoOrariDomiciliariList[i].populated.oraDa && !annullamentoOrariDomiciliariList[i].populated.oraA)) {
                return false;
            }
        }
        return true;
    }

    const [errorFieldsForAnnulamenti, setErrorFieldsForAnnullamenti] = useState([]);
    const [errorMessagesForOrariDomiciliari, setErrorMessagesForOrariDomiciliari] = useState(null);
    const [errorMessagesForAnnullamenti, setErrorMessagesForAnnullamenti] = useState(null);
    const [errorMessagesForAnnullamentiOraDaOraA, setErrorMessagesForAnnullamentiOraDaOraA] = useState(null);

    const isDatDaDaBeforeDataA = (dataDa, dataA) => {
        const da = new Date(dataDa);
        const a = new Date(dataA);

        return da.getTime() <= a.getTime();
    }

    const isDataDaDaEqualToDataA = (dataDa, dataA) => {
        const da = new Date(dataDa);
        const a = new Date(dataA);

        return da.getTime() === a.getTime();
    }


    const annullamentiListCheckForDataDaToBeBeforeDataA = () => {
        let errorFields = [];
        for (let i = 0; i < annullamentoOrariDomiciliariList.length; ++i) {
            if (annullamentoOrariDomiciliariList[i].populated.dataDa && annullamentoOrariDomiciliariList[i].populated.dataA) {
                if (!isDatDaDaBeforeDataA(annullamentoOrariDomiciliariList[i].annullamenti.dataDa, annullamentoOrariDomiciliariList[i].annullamenti.dataA)) {
                    errorFields.push({
                        "equalDates": false,
                        "da": annullamentoOrariDomiciliariList[i].annullamenti.dataDa,
                        "a": annullamentoOrariDomiciliariList[i].annullamenti.dataA
                    })
                } else if (isDataDaDaEqualToDataA(annullamentoOrariDomiciliariList[i].annullamenti.dataDa, annullamentoOrariDomiciliariList[i].annullamenti.dataA)
                    && annullamentoOrariDomiciliariList[i].populated.oraDa && annullamentoOrariDomiciliariList[i].populated.oraA &&
                    !oraDaOraATimeValidation(annullamentoOrariDomiciliariList[i].annullamenti.oraDa, annullamentoOrariDomiciliariList[i].annullamenti.oraA)) {
                    errorFields.push({
                        "equalDates": true,
                        "da": annullamentoOrariDomiciliariList[i].annullamenti.oraDa,
                        "a": annullamentoOrariDomiciliariList[i].annullamenti.oraA
                    })
                }
            }
        }

        setErrorFieldsForAnnullamenti([...errorFields]);
        if (errorFields.length > 0) {
            return false;
        }
        return true;
    }

    const invalidAnnullamentoOrariDomiciliariHours = "Errore nell'inserimento degli annullamento orari domiciliari: l'ora di inizio deve essere precedente all'ora di fine!";
    const isValidAnnullamentoOrariDomiciliari = () => {
        for (let i = 0; i < annullamentoOrariDomiciliariList.length; i++) {
            if ((annullamentoOrariDomiciliariList[i].populated.oraDa && annullamentoOrariDomiciliariList[i].populated.oraA) &&
                (!oraDaOraATimeValidation(annullamentoOrariDomiciliariList[i].annullamenti.oraDa, annullamentoOrariDomiciliariList[i].annullamenti.oraA))) {
                return false;
            }
        }

        return true;
    }

    const setErrorMessageWithErrorsForAnnullamenti = errorData => {
        let errorMessages = "";
        for (let i = 0; i < errorData.length; ++i) {
            if (!errorData[i].equalDates) {
                const da = new Date(errorData[i].da);
                const a = new Date(errorData[i].a);
                errorMessages += "Errore nell'annullamento: la data di inizio (" + da.getDate() + '/' + (da.getMonth() + 1) + '/' + da.getFullYear() + ") deve essere precedente alla data di fine (" + a.getDate() + '/' + (a.getMonth() + 1) + '/' + a.getFullYear() + ")!";
            }
            else {
                errorMessages += "Errore nell'annullamento: l'ora di inizio (" + errorData[i].da + ") deve essere precedente all'ora di fine (" + errorData[i].a + ")!";
            }
            if (i !== errorData.length - 1) {
                errorMessages += "\n";
            }
        }
        setErrorMessagesForAnnullamenti(errorMessages);
    }

    useEffect(() => {
        setErrorMessageWithErrorsForAnnullamenti(errorFieldsForAnnulamenti);
    }, [errorFieldsForAnnulamenti])

    const salvaClick = () => {
        durataAppuntamentoService.validate({}).then((resp) => {
            if (!resp.data) {
                togglePopulateDurataAppuntamenti(true);
                return;
            } else {
                setMessageFromRequest({
                    'state': false,
                    'text': '',
                    'type': ''
                });
                setErrorMessagesForOrariDomiciliari(null);
                if (isAnnullamentiListValid()) {
                    let isValidInput = true;
                    if (!areAnnullamentiListRequiredFieldsPopulatedForSalva()) {
                        setIsSalvaClicked(true);
                        isValidInput = false;
                    }
                    if (!isValidDomiciliareOrari() && isDomiciliareOrariWithinRange()) {
                        setIsSalvaClicked(true);
                        setErrorMessagesForOrariDomiciliari(invalidDomiciliareOrariMessage);
                        isValidInput = false;
                    }
                    if (!isDomiciliareOrariWithinRange()) {
                        setIsSalvaClicked(true);
                        isValidInput = false;
                    }
                    if (!isValidAnnullamentoOrariDomiciliari()) {
                        setIsSalvaClicked(true);
                        setErrorMessagesForAnnullamentiOraDaOraA(invalidAnnullamentoOrariDomiciliariHours);
                        isValidInput = false;
                    }
                    if (!annullamentiListCheckForDataDaToBeBeforeDataA()) {
                        isValidInput = false;
                    }
                    if (isValidInput) {
                        let payloadForAnnullamenti;

                        if (annullamentoOrariDomiciliariList.length === 1 && !annullamentoOrariDomiciliariList[0].isChanged) {
                            payloadForAnnullamenti = [];
                        } else {
                            payloadForAnnullamenti = annullamentoOrariDomiciliariList
                                .map((elem) => {
                                    return {
                                        ...elem.annullamenti,
                                        "oraA": elem.annullamenti.oraA,
                                        "oraDa": elem.annullamenti.oraDa
                                    };
                                });
                        }

                        const payloadForOrariDomiciliari = {
                            "idDomiciliareOrari": domiciliareOrari.idDomiciliareOrari,
                            "lunMattinaDa": domiciliareOrari.lunMattinaDa,
                            "lunMattinaA": domiciliareOrari.lunMattinaA,
                            "lunPomeriggioDa": domiciliareOrari.lunPomeriggioDa,
                            "lunPomeriggioA": domiciliareOrari.lunPomeriggioA,
                            "marMattinaDa": domiciliareOrari.marMattinaDa,
                            "marMattinaA": domiciliareOrari.marMattinaA,
                            "marPomeriggioDa": domiciliareOrari.marPomeriggioDa,
                            "marPomeriggioA": domiciliareOrari.marPomeriggioA,
                            "merMattinaDa": domiciliareOrari.merMattinaDa,
                            "merMattinaA": domiciliareOrari.merMattinaA,
                            "merPomeriggioDa": domiciliareOrari.merPomeriggioDa,
                            "merPomeriggioA": domiciliareOrari.merPomeriggioA,
                            "gioMattinaDa": domiciliareOrari.gioMattinaDa,
                            "gioMattinaA": domiciliareOrari.gioMattinaA,
                            "gioPomeriggioDa": domiciliareOrari.gioPomeriggioDa,
                            "gioPomeriggioA": domiciliareOrari.gioPomeriggioA,
                            "venMattinaDa": domiciliareOrari.venMattinaDa,
                            "venMattinaA": domiciliareOrari.venMattinaA,
                            "venPomeriggioDa": domiciliareOrari.venPomeriggioDa,
                            "venPomeriggioA": domiciliareOrari.venPomeriggioA
                        }

                        const payload = {
                            "requestDomiciliareOrari": {
                                ...payloadForOrariDomiciliari
                            },
                            "requestDomicliareAnnullamentiList": [
                                ...payloadForAnnullamenti
                            ]
                        }
                        wait(domiciliareService.modifyVisiteDomicilio(payload))
                            .then(
                                setMessageFromRequest({
                                    'state': true,
                                    'text': 'Visite Domicilio modificate correttamente!',
                                    'type': 'success'
                                })
                            )
                            .catch((err) => {
                                if (err.response && err.response.status === 512) {
                                    toggleTermsAndConditions(true);
                                } else {
                                    setMessageFromRequest({
                                        'state': true,
                                        'text': err.response ? err.response.data : "Errore durante la modifica.",
                                        'type': 'danger'
                                    });
                                }
                            });
                    }

                } else {
                    // do not send request // tell the user there are validation errors
                }
            }
        })

    }

    const [isOpenSalva, toggleModalSalva] = useState(false);

    const confirmSalvaClick = () => {
        salvaClick();
        toggleModalSalva(!isOpenSalva);
    }

    return (<Container fluid>
        <CustomBreadCrumb
            first={{
                "name": "Gestione Visite Domicilio",
                "onClick": () => { },
                "active": true
            }}
        />
        <Accordion>
            <AccordionHeader
                className='accordionButton'
                active={collapseOpen1}
                onToggle={() => setCollapseOpen1(!collapseOpen1)}
            >
                Orario Visite Domiciliari
            </AccordionHeader>
            <AccordionBody active={collapseOpen1}>
                <Row className='rowStyling' >
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Lunedi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.lunMattinaDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.lunMattinaDa}
                                            onChange={(value) => { handleChangeDomiciliareOrari("lunMattinaDa", value); }} />
                                        {(isSalvaClicked && ((domiciliareOrari.lunMattinaA !== null && domiciliareOrari.lunMattinaA !== '')
                                            && (domiciliareOrari.lunMattinaDa === null || domiciliareOrari.lunMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.lunMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.lunMattinaA}
                                            placeholder='alle'
                                            value={domiciliareOrari.lunMattinaA}
                                            onChange={(value) => handleChangeDomiciliareOrari("lunMattinaA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.lunMattinaDa !== null && domiciliareOrari.lunMattinaDa !== '')
                                            && (domiciliareOrari.lunMattinaA === null || domiciliareOrari.lunMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.lunMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.lunPomeriggioDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.lunPomeriggioDa}
                                            onChange={(value) => handleChangeDomiciliareOrari("lunPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.lunPomeriggioA !== null && domiciliareOrari.lunPomeriggioA !== '')
                                            && (domiciliareOrari.lunPomeriggioDa === null || domiciliareOrari.lunPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.lunPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.lunPomeriggioA}
                                            placeholder='alle'
                                            value={domiciliareOrari.lunPomeriggioA}
                                            onChange={(value) => handleChangeDomiciliareOrari("lunPomeriggioA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.lunPomeriggioDa !== null && domiciliareOrari.lunPomeriggioDa !== '')
                                            && (domiciliareOrari.lunPomeriggioA === null || domiciliareOrari.lunPomeriggioA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.lunPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Martedi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.marMattinaDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.marMattinaDa}
                                            onChange={(value) => handleChangeDomiciliareOrari("marMattinaDa", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.marMattinaA !== null && domiciliareOrari.marMattinaA !== '')
                                            && (domiciliareOrari.marMattinaDa === null || domiciliareOrari.marMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.marMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.marMattinaA}
                                            placeholder='alle'
                                            value={domiciliareOrari.marMattinaA}
                                            onChange={(value) => handleChangeDomiciliareOrari("marMattinaA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.marMattinaDa !== null && domiciliareOrari.marMattinaDa !== '')
                                            && (domiciliareOrari.marMattinaA === null || domiciliareOrari.marMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.marMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.marPomeriggioDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.marPomeriggioDa}
                                            onChange={(value) => handleChangeDomiciliareOrari("marPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.marPomeriggioA !== null && domiciliareOrari.marPomeriggioA !== '')
                                            && (domiciliareOrari.marPomeriggioDa === null || domiciliareOrari.marPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.marPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.marPomeriggioA}
                                            placeholder='alle'
                                            value={domiciliareOrari.marPomeriggioA}
                                            onChange={(value) => handleChangeDomiciliareOrari("marPomeriggioA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.marPomeriggioDa !== null && domiciliareOrari.marPomeriggioDa !== '')
                                            && (domiciliareOrari.marPomeriggioA === null || domiciliareOrari.marPomeriggioA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.marPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Mercoledi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.merMattinaDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.merMattinaDa}
                                            onChange={(value) => handleChangeDomiciliareOrari("merMattinaDa", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.merMattinaA !== null && domiciliareOrari.merMattinaA !== '')
                                            && (domiciliareOrari.merMattinaDa === null || domiciliareOrari.merMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.merMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.merMattinaA}
                                            placeholder='alle'
                                            value={domiciliareOrari.merMattinaA}
                                            onChange={(value) => handleChangeDomiciliareOrari("merMattinaA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.merMattinaDa !== null && domiciliareOrari.merMattinaDa !== '')
                                            && (domiciliareOrari.merMattinaA === null || domiciliareOrari.merMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.merMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.merPomeriggioDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.merPomeriggioDa}
                                            onChange={(value) => handleChangeDomiciliareOrari("merPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.merPomeriggioA !== null && domiciliareOrari.merPomeriggioA !== '')
                                            && (domiciliareOrari.merPomeriggioDa === null || domiciliareOrari.merPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.merPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.merPomeriggioA}
                                            placeholder='alle'
                                            value={domiciliareOrari.merPomeriggioA}
                                            onChange={(value) => handleChangeDomiciliareOrari("merPomeriggioA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.merPomeriggioDa !== null && domiciliareOrari.merPomeriggioDa !== '')
                                            && (domiciliareOrari.merPomeriggioA === null || domiciliareOrari.merPomeriggioA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.merPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Giovedi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.gioMattinaDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.gioMattinaDa}
                                            onChange={(value) => handleChangeDomiciliareOrari("gioMattinaDa", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.gioMattinaA !== null && domiciliareOrari.gioMattinaA !== '')
                                            && (domiciliareOrari.gioMattinaDa === null || domiciliareOrari.gioMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.gioMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.gioMattinaA}
                                            placeholder='alle'
                                            value={domiciliareOrari.gioMattinaA}
                                            onChange={(value) => handleChangeDomiciliareOrari("gioMattinaA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.gioMattinaDa !== null && domiciliareOrari.gioMattinaDa !== '')
                                            && (domiciliareOrari.gioMattinaA === null || domiciliareOrari.gioMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.gioMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.gioPomeriggioDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.gioPomeriggioDa}
                                            onChange={(value) => handleChangeDomiciliareOrari("gioPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.gioPomeriggioA !== null && domiciliareOrari.gioPomeriggioA !== '')
                                            && (domiciliareOrari.gioPomeriggioDa === null || domiciliareOrari.gioPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.gioPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.gioPomeriggioA}
                                            placeholder='alle'
                                            value={domiciliareOrari.gioPomeriggioA}
                                            onChange={(value) => handleChangeDomiciliareOrari("gioPomeriggioA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.gioPomeriggioDa !== null && domiciliareOrari.gioPomeriggioDa !== '')
                                            && (domiciliareOrari.gioPomeriggioA === null || domiciliareOrari.gioPomeriggioA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.gioPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={2}>
                        <Container className='daysOfTheWeekSizing'>
                            <h6 className='mt-4 text-secondary'>Venerdi</h6>
                            <Container className='removeZIndex'>
                                <h6 className='mt-2 mb-2 text-secondary'>Mattina</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.venMattinaDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.venMattinaDa}
                                            onChange={(value) => handleChangeDomiciliareOrari("venMattinaDa", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.venMattinaA !== null && domiciliareOrari.venMattinaA !== '')
                                            && (domiciliareOrari.venMattinaDa === null || domiciliareOrari.venMattinaDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.venMattinaDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.venMattinaA}
                                            placeholder='alle'
                                            value={domiciliareOrari.venMattinaA}
                                            onChange={(value) => handleChangeDomiciliareOrari("venMattinaA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.venMattinaDa !== null && domiciliareOrari.venMattinaDa !== '')
                                            && (domiciliareOrari.venMattinaA === null || domiciliareOrari.venMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validMattinaHours(domiciliareOrari.venMattinaA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidMattinaHours}</label>}
                                    </Col>
                                </Row>

                                <h6 className='mt-2 mb-2 text-secondary'>Pomeriggio</h6>
                                <Row>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.venPomeriggioDa}
                                            placeholder='dalle'
                                            value={domiciliareOrari.venPomeriggioDa}
                                            onChange={(value) => handleChangeDomiciliareOrari("venPomeriggioDa", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.venPomeriggioA !== null && domiciliareOrari.venPomeriggioA !== '')
                                            && (domiciliareOrari.venPomeriggioDa === null || domiciliareOrari.venPomeriggioDa === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.venPomeriggioDa) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                    <Col xs={6}>
                                        <CustomTimeField
                                            key={domiciliareOrari.venPomeriggioA}
                                            placeholder='alle'
                                            value={domiciliareOrari.venPomeriggioA}
                                            onChange={(value) => handleChangeDomiciliareOrari("venPomeriggioA", value)} />
                                        {(isSalvaClicked && ((domiciliareOrari.venPomeriggioA !== null && domiciliareOrari.venPomeriggioA !== '')
                                            && (domiciliareOrari.lunMattinaDa === null || domiciliareOrari.lunMattinaA === '')))
                                            && <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                        {!validPomeriggioHours(domiciliareOrari.venPomeriggioA) &&
                                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidPomeriggioHours}</label>}
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                </Row>
            </AccordionBody>

            <AccordionHeader
                className='accordionButton'
                active={collapseOpen2}
                onToggle={() => setCollapseOpen2(!collapseOpen2)}
            >
                Annullamento Orari Domiciliari
            </AccordionHeader>
            <AccordionBody active={collapseOpen2}>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>

                    <Row>
                        <Col>
                            {annullamentoOrariDomiciliariList.map((data, index) => {
                                return (
                                    <Row key={annullamentoOrariDomiciliariList[index].key} style={{ columnGap: "2em", justifyContent: "space-around" }}>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Row>
                                                <h6 className='mt-2 text-secondary'>Periodo</h6>
                                            </Row>
                                            <Row>
                                                <Col xs={6} >
                                                    <CustomDateField
                                                        key={clearDataForAnnullamenti}
                                                        placeholder="dal"
                                                        value={data.annullamenti.dataDa}
                                                        onChange={(event) => handleChangeAnnullamentoOrariDomicilio("dataDa", event.target.value, index)}
                                                    />
                                                    {(((isSalvaClicked && hasLoaded && (annullamentoOrariDomiciliariList.length > 1 || data.isChanged)) || showAnnullamentiRequirements)
                                                        && !data.populated.dataDa) &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                                </Col>
                                                <Col xs={6} >
                                                    <CustomDateField
                                                        key={clearDataForAnnullamenti}
                                                        placeholder="al"
                                                        value={data.annullamenti.dataA}
                                                        onChange={(event) => handleChangeAnnullamentoOrariDomicilio("dataA", event.target.value, index)}
                                                    />
                                                    {(((isSalvaClicked && hasLoaded && (annullamentoOrariDomiciliariList.length > 1 || data.isChanged)) || showAnnullamentiRequirements)
                                                        && !data.populated.dataA) &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <Row>
                                                <h6 className='mt-2 text-secondary'>Fascia Oraria</h6>
                                            </Row>
                                            <Row className='removeZIndex'>
                                                <Col>
                                                    <CustomTimeField
                                                        key={data.annullamenti.oraDa}
                                                        placeholder='dalle'
                                                        value={data.annullamenti.oraDa}
                                                        onChange={(value) => handleChangeAnnullamentoOrariDomicilio("oraDa", value, index)} />

                                                    {((((isSalvaClicked && hasLoaded && (annullamentoOrariDomiciliariList.length > 1 || data.isChanged)) || showAnnullamentiRequirements)
                                                        && !data.populated.oraDa && data.populated.oraA) &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>)}
                                                </Col>
                                                <Col>
                                                    <CustomTimeField
                                                        key={data.annullamenti.oraA}
                                                        placeholder='alle'
                                                        value={data.annullamenti.oraA}
                                                        onChange={(value) => handleChangeAnnullamentoOrariDomicilio("oraA", value, index)} />

                                                    {((((isSalvaClicked && hasLoaded && (annullamentoOrariDomiciliariList.length > 1 || data.isChanged)) || showAnnullamentiRequirements)
                                                        && !data.populated.oraA && data.populated.oraDa) &&
                                                        <label style={{ color: "red", fontSize: "0.70em" }}>{requiredFielldLabel}</label>)}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                                            <Row style={{ marginTop: "2.5em" }}>
                                                <Col>
                                                    <Icon id={'minus_button' + index} icon="it-minus" color="white" style={{ backgroundColor: "#115287", "marginLeft": "5%", borderRadius: "10px" }} onClick={() => removeFromAnnullamentoOrariDomiciliariList(index)}></Icon>
                                                    <UncontrolledTooltip
                                                        placement='top'
                                                        target={'minus_button' + index}
                                                    >
                                                        Elimina riga
                                                    </UncontrolledTooltip>
                                                    {index === annullamentoOrariDomiciliariList.length - 1 &&
                                                        <span>
                                                            <Icon id={'plus_button' + index} icon="it-plus" color="white" style={{ backgroundColor: "#115287", "marginLeft": "5%", borderRadius: "10px" }} onClick={() => addToAnnullamentoOrariDomiciliariList()}></Icon>
                                                            <UncontrolledTooltip
                                                                placement='top'
                                                                target={'plus_button' + index}
                                                            >
                                                                Aggiungi riga
                                                            </UncontrolledTooltip>
                                                        </span>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            })
                            }
                        </Col>
                    </Row>
                </Col>
            </AccordionBody>

        </Accordion>
        <Container fluid style={{ marginBottom: "3%" }}>
            {(messageFromRequest.state) && <Alert color={messageFromRequest.type} className='display-linebreak'>{messageFromRequest.text}</Alert>}
            {(errorMessagesForOrariDomiciliari) && <Alert color='danger' className='display-linebreak'>{errorMessagesForOrariDomiciliari}</Alert>}
            {(errorMessagesForAnnullamenti) && <Alert color='danger' className='display-linebreak'>{errorMessagesForAnnullamenti}</Alert>}
            {(errorMessagesForAnnullamentiOraDaOraA) && <Alert color='danger' className='display-linebreak'>{errorMessagesForAnnullamentiOraDaOraA}</Alert>}
            <Row style={{ display: "flex", justifyContent: "end" }}>
                <Col xs={10} sm={8} lg={3} xl={2} style={{ display: "flex", margin: "5px 0px" }}>
                    <Button className="buttonPrimary" onClick={() => toggleModalSalva(!isOpenSalva)} disabled={messageFromRequest.type === 'danger'}>SALVA</Button>
                    <Modal
                        isOpen={isOpenSalva}
                        toggle={() => toggleModalSalva(!isOpenSalva)}
                    >
                        <ModalHeader toggle={() => toggleModalSalva(!isOpenSalva)} id='esempio12'>
                            CONFERMA
                        </ModalHeader>
                        <ModalBody>
                            <p>Confermi il salvataggio delle modifiche?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="buttonPrimary" onClick={() => toggleModalSalva(!isOpenSalva)}>
                                Annulla
                            </Button>
                            <Button className="buttonPrimary" onClick={confirmSalvaClick}>
                                Conferma
                            </Button>
                        </ModalFooter>
                    </Modal>{' '}
                </Col>
            </Row>
        </Container>
    </Container>



    )
}

export default VisiteDomicilioPage;