import { Input, Icon } from "design-react-kit"
import { useEffect, useRef, useState } from "react";
import { HHMM_TIME_FORMAT } from "../constants/regexConstants";

const TimeInputContainer = ({
    children,
    button,
}) => {
    return (
        <div
        >
            <div className='input-group'>
                {children}
                <div className='input-group-append'>{button}</div>
            </div>
        </div>
    );
};

export const CustomTimeField = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownClicked, setIsDropdownClicked] = useState(false);
    const [isValidTime, setIsValidTime] = useState(true);

    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [time, setTime] = useState('');
    const [valueToSend, setValueToSend] = useState('');

    useEffect(() => {
        if (props.value) {
            let value = props.value;
            value = value.length > 5 ? value.slice(0, 5) : value;
            if (!timeFormatValidation(value)) {
                console.error(`Could not parse ${props.value} to time format hh:mm!`);
            } else {
                let splitValue = value.split(':');
                setHours(splitValue[0]);
                setMinutes(splitValue[1]);
                setTime(value);
                setValueToSend(value);
            }
        }
    }, [])

    const invalidtimeLabel = 'Formato non valido (hh:mm)';
    const timeFormatValidation = (time) => {
        return !time || HHMM_TIME_FORMAT.test(time);
    }

    useEffect(() => {
        setIsValidTime(timeFormatValidation(time));
    }, [time])


    const addMissingZeros = time => {
        return time && time.length < 6 ? time + ":00" : time;
    }

    useEffect(() => {
        if (!isOpen && valueToSend != time) {
            props.onChange(valueToSend);
        }
    }, [isOpen]);

    useEffect(() => {
        if (time != null && time != '') {
            setValueToSend(addMissingZeros(time));
        } else {
            setValueToSend(null);
        }
    }, [time]);

    const addTime = (value, maxValue) => {
        const currTime = Number(value);
        return currTime >= maxValue ? value : (currTime + 1).toString();
    };

    const subtractTime = (value, minValue) => {
        const currTime = Number(value);
        return currTime <= minValue ? value : (currTime - 1).toString();
    };

    const formatHour = (value) => {
        if (!value || value < 0) value = 0;
        if (value > 23) value = 23;
        return `0${value}`.slice(-2);
    }

    const formatMinutes = (value) => {
        if (!value || value < 0) value = 0;
        if (value > 59) value = 59;
        return `0${value}`.slice(-2);
    }

    const onHoursChange = (value) => {
        const formattedValue = formatHour(value);
        setHours(isNaN(Number(formattedValue)) ? `00` : formattedValue);
        setTime(`${formattedValue}:${minutes}`);
    };

    const onMinutesChange = (value) => {
        const formattedValue = formatMinutes(value);
        setMinutes(isNaN(Number(formattedValue)) ? `00` : formattedValue);
        setTime(`${hours}:${formattedValue}`);
    };

    const button = (
        <button
            className='btn-time'
            onClick={() => {
                if (isDropdownClicked && !isOpen) {
                    setIsDropdownClicked(false);
                } else {
                    setIsOpen(!isOpen);
                }
            }}
        >
            <Icon icon='it-clock' />
        </button>
    );

    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    return (
        <div
            className='it-timepicker-wrapper'
            onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget) && isOpen) {
                    setIsDropdownClicked(false);
                    setIsOpen(false)
                }
            }}
        >
            <section className='time-spinner'>
                <fieldset>
                    <div>
                        <div className='calendar-input-container' style={{ display: "block" }}>
                            <TimeInputContainer
                                wrapperClass={props.wrapperClass}
                                label={props.label}
                                id={props.id}
                                button={button}
                                invalid={props.invalid}
                            >
                                <Input
                                    {...props}
                                    type='text'
                                    label={props.label}
                                    id={props.id}
                                    placeholder={props.placeholder}
                                    value={time}
                                    disabled
                                    invalid={props.invalid}
                                    noWrapper
                                />
                            </TimeInputContainer>
                        </div>
                        {!isValidTime &&
                            <label style={{ color: "red", fontSize: "0.70em" }}>{invalidtimeLabel}</label>}
                    </div>
                </fieldset>
                <div
                    className='spinner-control'
                    aria-hidden='true'
                    role='region'
                    aria-label={props.controlsLabel}
                    tabIndex={-1}
                    style={{ display: isOpen ? 'block' : 'none' }}
                    onFocus={() => {
                        setIsDropdownClicked(true)
                    }}
                    onBlur={(event) => {
                        if (!event.currentTarget.contains(event.relatedTarget) && isOpen) {
                            setIsDropdownClicked(true);
                            setIsOpen(false)
                        }
                    }}
                >
                    <div className='spinner-hour spinner'>
                        <Input
                            className='spinnerHour form-control'
                            aria-label={props.hoursLabel}
                            type='number'
                            value={hours}
                            role='spinbutton'
                            maxLength={2}
                            aria-valuenow={Number(hours)}
                            aria-valuemax={23}
                            aria-valuemin={0}
                            onChange={(e) =>
                                onHoursChange(e.target.value)
                            }
                            onKeyDown={(e) => {
                                exceptThisSymbols.includes(e.key) && e.preventDefault()
                            }}
                        />
                        <div className='button-wrapper'>
                            <button
                                className='button-hour btnHourUp'
                                aria-label={props.increaseHoursLabel}
                                aria-hidden='true'
                                tabIndex={-1}
                                onClick={() => onHoursChange(addTime(hours, 23))}
                            >
                                <span className='icon-up'></span>
                            </button>
                            <button
                                className='button-hour btnHourDown'
                                aria-label={props.decreaseHoursLabel}
                                aria-hidden='true'
                                tabIndex={-1}
                                onClick={() => onHoursChange(subtractTime(hours, 0))}
                            >
                                <span className='icon-down'></span>
                            </button>
                        </div>
                    </div>
                    <div className='spinner-min spinner'>
                        <Input
                            className='spinnerMin form-control'
                            aria-label={props.minutesLabel}
                            type='number'
                            value={minutes}
                            role='spinbutton'
                            maxLength={2}
                            aria-valuenow={Number(minutes)}
                            aria-valuemax={59}
                            aria-valuemin={0}
                            onChange={(e) =>
                                onMinutesChange(e.target.value)
                            }
                            onKeyDown={(e) => {
                                exceptThisSymbols.includes(e.key) && e.preventDefault()
                            }}
                        />
                        <div className='button-wrapper'>
                            <button
                                className='btnMinUp button-minute'
                                aria-label={props.increaseMinutesLabel}
                                aria-hidden='true'
                                tabIndex={-1}
                                onClick={() => onMinutesChange(addTime(minutes, 59))}
                            >
                                <span className='icon-up'></span>
                            </button>
                            <button
                                className='btnMinDown button-minute'
                                aria-label={props.decreaseMinutesLabel}
                                aria-hidden='true'
                                tabIndex={-1}
                                onClick={() => onMinutesChange(subtractTime(minutes, 0))}
                            >
                                <span className='icon-down'></span>
                            </button>
                        </div>
                    </div>
                    <div className='button-wrapper'>
                        <button
                            style={{ padding: '0px 5px' }}
                            aria-hidden='true'
                            tabIndex={-1}
                            onClick={() => {
                                setTime('');
                                setHours('00');
                                setMinutes('00');
                            }}
                        >
                            <>
                                Reimposta
                            </>
                        </button>
                    </div>
                </div>
            </section>
        </div>
    )
}