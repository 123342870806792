import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const listaComuni = payload => {
    return axios.post(`${BASE_ENDPOINT}/comune/listaComuni`, payload);
};

export default {
    listaComuni
};