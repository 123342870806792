import axios from 'axios';

const BASE_ENDPOINT = window._env_.BASE_ENDPOINT;

const searchListaStudi = payload => {
    //return axios.post("http://localhost:8080/studi-medico/search", payload);
    return axios.post(`${BASE_ENDPOINT}/studi-medico/search`, payload);
};

const getOrari = (idStudio) => {
    const payload = { idStudio: idStudio }
    //return axios.post("http://localhost:8080/studio-orari/search", payload);
    return axios.post(`${BASE_ENDPOINT}/studio-orari/search`, payload);
};

const deleteStudio = payload => {
    return axios.post(`${BASE_ENDPOINT}/studio/delete`, payload);
    //return axios.post("http://localhost:8080/studio/delete", payload);
};

const searchStudioById = id => {
    const payload = { "idStudio": id }
    return axios.post(`${BASE_ENDPOINT}/studio/search`, payload);
    //return axios.post("http://localhost:8080/studio/delete", payload);
};

const searchStudioOrariById = id => {
    const payload = { "idStudio": id }
    return axios.post(`${BASE_ENDPOINT}/studio-orari/search`, payload);
    //return axios.post("http://localhost:8080/studio/delete", payload);
};

const searchComuneByCodIstatComune = codIstatComune => {
    const payload = { "codIstatComune": codIstatComune }
    return axios.post(`${BASE_ENDPOINT}/comune/search`, payload);
    //return axios.post("http://localhost:8080/studio/delete", payload);
};

const searchStudioAnnullamentiByStudioId = idStudio => {
    const payload = { "idStudio": idStudio }
    return axios.post(`${BASE_ENDPOINT}/studio-annullamenti/search`, payload);
    //return axios.post("http://localhost:8080/studio/delete", payload);
};

const searchSostitutiByStudioId = idStudio => {
    const payload = { "idStudio": idStudio }
    return axios.post(`${BASE_ENDPOINT}/sostituzioni/search`, payload);
    //return axios.post("http://localhost:8080/studio/delete", payload);
};

const studiMedicoInsert = payload => {
    return axios.post(`${BASE_ENDPOINT}/studi-medico/insert`, payload);
    //return axios.post("http://localhost:8080/studio/delete", payload);
};

const studiMedicoUpdate = payload => {
    return axios.post(`${BASE_ENDPOINT}/studi-medico/update`, payload);
    //return axios.post("http://localhost:8080/studio/delete", payload);
};

const studioOrariInsert = payload => {
    return axios.post(`${BASE_ENDPOINT}/studio-orari/insert`, payload);
};

export default {
    searchListaStudi,
    getOrari,
    deleteStudio,
    searchStudioById,
    searchStudioOrariById,
    searchComuneByCodIstatComune,
    searchStudioAnnullamentiByStudioId,
    searchSostitutiByStudioId,
    studiMedicoInsert,
    studiMedicoUpdate,
    studioOrariInsert
};