import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import packageInfo from "../package.json";
import CustomFooter from "./components/common/CustomFooter";
import CustomHeader from "./components/common/CustomHeader";
import Loading from './components/common/Loading';
import { PopulateDurataAppuntamentiModal } from './components/custom/PopulateDurataAppuntamentiModal';
import { TermsAndConditionsModal } from './components/custom/TermsAndConditionsModal';
import RootPage from "./components/RootPage";
import keycloak from './keycloak';



function App() {
  const [isPageChanged, setIsPageChanged] = useState(false);
  const [termsAndConditionsModalIsOpen, toggleTermsAndConditions] = useState(false);
  const [populateDurataAppuntamentiModalIsOpen, togglePopulateDurataAppuntamenti] = useState(false);
  return (
    <ReactKeycloakProvider authClient={keycloak} onReady={() => { keycloak.updateToken(5) }} initOptions={{ onLoad: 'login-required', pkceMethod: 'S256' }} >
      <Router basename={packageInfo.homepage}>
        <Loading/>
        <CustomHeader isPageChanged={isPageChanged} setIsPageChanged={setIsPageChanged}/>
        <RootPage populateDurataAppuntamentiModalIsOpen={populateDurataAppuntamentiModalIsOpen} togglePopulateDurataAppuntamenti={togglePopulateDurataAppuntamenti} 
          toggleTermsAndConditions={toggleTermsAndConditions} isPageChanged={isPageChanged} setIsPageChanged={setIsPageChanged}/>
        <TermsAndConditionsModal togglePopulateDurataAppuntamenti={togglePopulateDurataAppuntamenti}
          termsAndConditionsModalIsOpen={termsAndConditionsModalIsOpen} toggleTermsAndConditions={toggleTermsAndConditions}/>
        <PopulateDurataAppuntamentiModal
          togglePopulateDurataAppuntamenti={togglePopulateDurataAppuntamenti}
          populateDurataAppuntamentiModalIsOpen={populateDurataAppuntamentiModalIsOpen}
        />              
        <CustomFooter />
      </Router>
    </ReactKeycloakProvider>
  );
}

export default App;